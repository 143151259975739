import React, { useState, useEffect } from "react";
import Axios from "../../../../../api/axios/Axios";
import { TextField, TextArea } from "../../../../../components/input/Inputs";
import { Button } from "../../../../../components/button/Buttons";
import {
  Form,
  InputStack,
} from "../../../../../components/layout/layout/Layout";
import "./Form.css";
import { objectDataValidation } from "../../../../../hooks/Validation";
import { v4 as uuidv4 } from "uuid";
import Heading from "../../../../../components/heading/Heading";

const PartnerAddCouponForm = ({ closeForm, refreshForm }) => {
  const [couponCode, setCouponCode] = useState("");
  const [couponValue, setCouponValue] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [comment, setComment] = useState("");

  useEffect(() => generateRandomKey(), []);

  const generateRandomKey = () => {
    const newRandomKey = uuidv4();
    return setCouponCode(newRandomKey.substring(0, 6).toUpperCase());
  };

  const handleSubmit = () => {
    const data = {
      couponCode: couponCode,
      couponType: "Percent",
      couponValue: formatNumber(couponValue),
      expiryDate: expiryDate,
    };

    if (!objectDataValidation(data)) {
      return alert("Please complete all required fields.");
    }

    if (comment !== "") {
      data.comment = comment;
    } else data.comment = "";

    return apiAddNewCoupon(data);
  };

  const formatNumber = (number) => {
    if (number % 1 === 0) {
      return Math.round(number).toFixed(0);
    } else {
      return parseFloat(number).toFixed(2);
    }
  };

  const apiAddNewCoupon = (data) => {
    Axios.post("/add/partner/coupon", data)
      .then((res) => {
        const { success } = res.data;
        if (success) {
          refreshForm();
          return closeForm(false);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleCouponValueInput = (e) => {
    const value = e.target.value;
    if (!isNaN(value) || value === "") {
      return setCouponValue(value);
    }
  };

  const handleCouponExpiryDateInput = (e) => {
    const value = e.target.value;
    if (value.length > 10) return;
    return setExpiryDate(value);
  };

  const handleCouponCommentInput = (e) => {
    const value = e.target.value;
    if (value.length <= 300) {
      return setComment(value);
    }
  };

  return (
    <Form width={700}>
      <Heading title={"Add New Coupon"} color={"black"} />

      <br />
      <br />

      <InputStack>
        <TextField label="Discount Code" value={couponCode} disabled />

        <TextField
          label={"Coupon Value (%)"}
          type="number"
          value={couponValue}
          placeholder={"e.g. 50 (number only)"}
          onChange={(e) => handleCouponValueInput(e)}
        />

        <TextField
          type="date"
          label="Expiry Date"
          value={expiryDate}
          onChange={(e) => handleCouponExpiryDateInput(e)}
          placeholder="e.g. 01/07/2024"
        />

        <TextArea
          label="Note"
          placeholder="e.g. Player of the day"
          value={comment}
          onChange={(e) => handleCouponCommentInput(e)}
        />
      </InputStack>

      <br />

      <Button
        value="Add Discount Code"
        variant="fill"
        style={{ width: "100%" }}
        onClick={() => handleSubmit()}
      />

      <br />
    </Form>
  );
};

export default PartnerAddCouponForm;
