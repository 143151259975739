import React from "react";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../../../components/layout/container/Container";
import PartnerUploadsList from "./view/UploadsList";
import "./Matches.css";

const PartnerMatchesPage = () => {
  return (
    <Main>
      <Content>
        <ContentWrapper>
          <PartnerUploadsList />
        </ContentWrapper>
      </Content>
    </Main>
  );
};

export default PartnerMatchesPage;
