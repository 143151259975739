import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../../api/axios/Axios";
import { Form, InputStack } from "../../../components/layout/layout/Layout";
import Heading from "../../../components/heading/Heading";
import { objectDataValidation } from "../../../hooks/Validation";
import { Button } from "../../../components/button/Buttons";
import { TextField } from "../../../components/input/Inputs";
import { Main, Content } from "../../../components/layout/container/Container";
import "../Access.css";

const PartnerSignInPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const navigateTo = useNavigate();

  const apiPartnerSignIn = (data) => {
    Axios.post("/partner/sign-in", data)
      .then((res) => {
        const { success, message } = res.data;
        if (!success) return setMessage(message);
        return (window.location.href = "/partner/matches");
      })
      .catch((err) => console.error(err));
  };

  const handleSubmit = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(email)) {
      return alert("Invalid email format.");
    } else {
      const data = { email: email, password: password };

      if (!objectDataValidation(data))
        return alert("Please complete all required fields.");

      return apiPartnerSignIn(data);
    }
  };

  const HandleKeyDown = (e) => e.keyCode === 13 && handleSubmit(e);

  return (
    <Main>
      <Content>
        <Form width={500}>
          <Heading title={"Sign In as Partner"} color={"black"} />

          <br />
          <br />

          {message && (
            <>
              <div
                style={{
                  color: "white",
                  padding: 8,
                  background: "#ff4040",
                  textAlign: "center",
                  borderRadius: 4,
                }}
              >
                {message}
              </div>

              <br />
            </>
          )}

          <InputStack>
            <TextField
              type="email"
              label="Email"
              value={email}
              placeholder="e.g. email@domain.com"
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => HandleKeyDown(e)}
            />

            <TextField
              type="password"
              label="Password"
              value={password}
              placeholder="********"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => HandleKeyDown(e)}
              showPasswordOption
            />

            <button
              type="button"
              onClick={() => navigateTo("/partner/forgot-password")}
              className="access-form__forgot-password"
            >
              Forgot Password
            </button>
          </InputStack>

          <br />

          <Button
            value="Sign In"
            variant="fill"
            onClick={(e) => handleSubmit(e)}
            style={{ width: "100%" }}
          />

          <br />

          {/* <span className="access-form__dont-have-an-account">
          Don't have an account?{" "}
          <button
            type="button"
            onClick={() => navigateTo("/partner/sign-up")}
            className="access-form__link"
          >
            Create account
          </button>
        </span> */}
        </Form>
      </Content>
    </Main>
  );
};

export default PartnerSignInPage;
