import React, { useEffect, useState } from "react";
import Axios from "../api/axios/Axios";

export const CheckLoginStatus = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  useEffect(() => HandleCheckLoginStatus(), []);

  const HandleCheckLoginStatus = () => {
    Axios.get("/api/user/isLoggedIn")
      .then((res) => {
        const auth = res.data;
        return setIsAuthenticated(auth);
      })
      .catch(() => setIsAuthenticated(false));
  };
  return isAuthenticated;
};

export const GetAuthorisedUserDetail = () => {
  const [userDetail, setUserDetail] = useState(null);
  useEffect(() => HandleAuthorisedUserDetail(), []);

  const HandleAuthorisedUserDetail = () => {
    Axios.get("/api/user/authorised-user-detail")
      .then((res) => setUserDetail(res.data))
      .catch(() => setUserDetail(false));
  };

  return userDetail;
};
