import React, { useState } from "react";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../../../components/layout/container/Container";
import "./Account.css";
import AdminProfileForm from "./form/PersonalDetailForm";
import AdminPasswordForm from "./form/PasswordForm";
import Filters from "./components/Filters";

const AdminAccountPage = () => {
  const [visibility, setVisibility] = useState("personalDetailForm");

  const toggleVisibility = (status) => {
    return setVisibility(status);
  };

  const handleDeleteButton = (e) => {
    const msg =
      "Please contact the RugbyGo administrator to request the deletion of your administrative account.";
    return alert(msg);
  };

  return (
    <Main>
      <Content>
        <ContentWrapper width={700}>
          <Filters
            visibility={visibility}
            personalDetailFormOnClick={() =>
              toggleVisibility("personalDetailForm")
            }
            passwordFormOnClick={() => toggleVisibility("passwordForm")}
            deleteAccountOnClick={() => handleDeleteButton()}
          />

          {visibility === "personalDetailForm" && <AdminProfileForm />}
          {visibility === "passwordForm" && <AdminPasswordForm />}
        </ContentWrapper>
      </Content>
    </Main>
  );
};

export default AdminAccountPage;
