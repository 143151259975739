import React from "react";
import "../../../../../components/styles/Filter.css";

const Filters = ({
  personalDetailFormOnClick,
  passwordFormOnClick,
  deleteAccountOnClick,
  visibility,
}) => {
  return (
    <ul className="main-filters__ul">
      <li
        className={visibility === "personalDetailForm" ? "active" : null}
        onClick={() => personalDetailFormOnClick()}
      >
        Personal Details
      </li>

      <li
        className={visibility === "passwordForm" ? "active" : null}
        onClick={() => passwordFormOnClick()}
      >
        Change Password
      </li>

      <li className="filter-delete-btn" onClick={() => deleteAccountOnClick()}>
        Delete Account
      </li>
    </ul>
  );
};

export default Filters;
