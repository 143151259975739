import React from "react";

const Filters = ({
  publishedOnClick,
  inReviewOnClick,
  declinedOnClick,
  visibility,
  publishedVideoCount,
  inReviewVideoCount,
  declinedVideoCount,
}) => {
  return (
    <ul className="main-filters__ul">
      <li
        className={visibility === "Published" ? "active" : null}
        onClick={() => publishedOnClick()}
      >
        Published {publishedVideoCount ? publishedVideoCount : 0}
      </li>

      <li
        className={visibility === "In Review" ? "active" : null}
        onClick={() => inReviewOnClick()}
      >
        In Review {inReviewVideoCount ? inReviewVideoCount : 0}
      </li>

      <li
        className={visibility === "Declined" ? "active" : null}
        onClick={() => declinedOnClick()}
      >
        Declined {declinedVideoCount ? declinedVideoCount : 0}
      </li>
    </ul>
  );
};

export default Filters;
