import React, { useEffect, useState } from "react";
import Axios from "../../../../../api/axios/Axios";
import "../Matches.css";
import MatchReviewForm from "../form/MatchReview";
import Filters from "../components/Filters";
import MatchesTable from "../components/Table";
import Modal from "../../../../../components/layout/modal/Modal";
import Heading from "../../../../../components/heading/Heading";

const AdminMatchesList = () => {
  const [videoData, setVideoData] = useState([]);
  const [openEditMatchReviewForm, setOpenEditMatchReviewForm] = useState(false);
  const [visibility, setVisibility] = useState("In Review");
  const [publishedVideoCount, setPublishedVideoCount] = useState(0);
  const [inReviewCount, setInReviewCount] = useState(0);
  const [declinedVideoCount, setDeclinedVideoCount] = useState(0);
  const [expiredVideoCount, setExpiredVideoCount] = useState(0);
  const [selectedItemId, setSelectedItemId] = useState({});

  useEffect(() => apiGetMatchesVideoCount(), []);
  useEffect(() => apiMatchestList(), [visibility]);

  const apiGetMatchesVideoCount = () => {
    Axios.get("/select/admin/match/count")
      .then((res) => {
        const data = res.data[0];
        setPublishedVideoCount(data.publishedCount);
        setInReviewCount(data.inReviewCount);
        setDeclinedVideoCount(data.declinedCount);
        return setExpiredVideoCount(data.expiredCount);
      })
      .catch((err) => console.error(err));
  };

  const apiMatchestList = () => {
    Axios.get("/select/admin/match/list", {
      params: { reviewStatus: visibility, offSet: 0 },
    })
      .then((res) => {
        const data = res.data;
        return setVideoData(data);
      })
      .catch((err) => console.error(err));
  };

  const handleEditMatch = (obj) => {
    setSelectedItemId(obj);
    return setOpenEditMatchReviewForm(true);
  };

  const toggleVisibility = (status) => {
    return setVisibility(status);
  };

  return (
    <>
      <Heading title="Matches" />

      <br />

      <Filters
        visibility={visibility}
        inReviewOnClick={() => toggleVisibility("In Review")}
        publishedOnClick={() => toggleVisibility("Published")}
        declinedOnClick={() => toggleVisibility("Declined")}
        publishedVideoCount={publishedVideoCount}
        inReviewVideoCount={inReviewCount}
        declinedVideoCount={declinedVideoCount}
        expiredVideoCount={expiredVideoCount}
      />

      <br />

      <MatchesTable data={videoData} iconOnClick={handleEditMatch} />

      <Modal
        openModal={openEditMatchReviewForm}
        closeModal={setOpenEditMatchReviewForm}
      >
        <MatchReviewForm
          refreshVideoList={apiMatchestList}
          refreshVideoCount={apiGetMatchesVideoCount}
          closeForm={setOpenEditMatchReviewForm}
          selectedItemId={selectedItemId}
        />
      </Modal>
    </>
  );
};

export default AdminMatchesList;
