import React from "react";
import "./CheckBox.css";

const CheckBox = (props) => {
  return (
    <div
      className={`c_checkbox__container ${props.position}`}
      style={props.containerStyle}
    >
      <input
        type="checkbox"
        checked={props.checked ? true : false}
        className={`c_checkbox__input ${props.boxSize}`}
        onChange={() => props.onChange()}
        style={props.inputStyle}
      />
      <label className="c_checkbox__label" style={props.labelStyle}>
        {props.label}
      </label>
    </div>
  );
};

export default CheckBox;
