import React, { useEffect, useState } from "react";
import { ConfirmedDialog } from "../../../../../components/dialog/Dialog";
import Axios from "../../../../../api/axios/Axios";
import {
  Form,
  InputStack,
  Heading,
} from "../../../../../components/layout/layout/Layout";
import { objectDataValidation } from "../../../../../hooks/Validation";
import { TextField } from "../../../../../components/input/Inputs";
import { Button } from "../../../../../components/button/Buttons";

const MemberPersonalDetailForm = ({ passMemberId }) => {
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [yearBorn, setYearBorn] = useState("");
  const [postcode, setPostcode] = useState("");
  const [toggleAlert, setToggleAlert] = useState(false);

  useEffect(() => apiGetMemberProfile(), []);

  const apiGetMemberProfile = () => {
    Axios.get("/select/member/profile")
      .then((res) => {
        const user = res.data[0];

        setFirstname(user.firstName);
        setLastname(user.lastName);
        setYearBorn(user.yearBorn);
        setPostcode(user.postcode);

        return passMemberId(user.memberId);
      })
      .catch((err) => console.error(err));
  };

  const handleProfileSubmit = () => {
    const data = {
      firstName: firstName,
      lastName: lastName,
      yearBorn: yearBorn,
      postcode: postcode,
    };

    if (!objectDataValidation(data))
      return alert("Please complete all required fields.");

    return apiUpdateMemberProfile(data);
  };

  const apiUpdateMemberProfile = (data) => {
    Axios.post("/update/member/profile", data)
      .then((res) => {
        const success = res.data.success;
        if (success) return setToggleAlert(true);
      })
      .catch((err) => console.error(err));
  };

  // 📌 Handle input data:

  const handleFirstNameInput = (e) => {
    if (e.target.value.length <= 45) {
      return setFirstname(e.target.value);
    }
  };

  const handleLastNameInput = (e) => {
    if (e.target.value.length <= 45) {
      return setLastname(e.target.value);
    }
  };

  const handleYearBorn = (e) => {
    if (e.target.value.length <= 4) {
      return setYearBorn(e.target.value);
    }
  };

  const handlePostcode = (e) => {
    if (e.target.value.length <= 4) {
      return setPostcode(e.target.value);
    }
  };

  return (
    <Form>
      <Heading title={"Personal Details"} />

      <br />

      <InputStack style={{ gap: 15 }}>
        <TextField
          label={"First Name"}
          value={firstName}
          onChange={(e) => handleFirstNameInput(e)}
        />
        <TextField
          label="Last Name"
          value={lastName}
          onChange={(e) => handleLastNameInput(e)}
        />

        <TextField
          type="number"
          label="Year Born"
          value={yearBorn}
          placeholder="e.g. 1980"
          onChange={(e) => handleYearBorn(e)}
        />

        <TextField
          type="number"
          label="Postcode"
          value={postcode}
          onChange={(e) => handlePostcode(e)}
        />
      </InputStack>

      <br />

      <Button
        variant="fill"
        style={{ width: "100%" }}
        onClick={() => handleProfileSubmit()}
        value="Update Profile"
      />

      <br />

      {toggleAlert && (
        <ConfirmedDialog
          status="success"
          message="Updated"
          open={setToggleAlert}
        >
          Updated
        </ConfirmedDialog>
      )}
    </Form>
  );
};

export default MemberPersonalDetailForm;
