import React from "react";
import { FormatDateToLocaleDateString } from "../../../../../hooks/DateTime";
import { RiEditLine } from "react-icons/ri";
import "../../../../../components/styles/Table.css";

const Table = ({ data, couponId }) => {
  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th className="table-th__icon"></th>
            <th>Coupon Code</th>
            <th>Type</th>
            <th>Value</th>
            <th>Redeemed?</th>
            <th>Expiry Date</th>
            <th>Created At</th>
            <th>Comment</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item) => (
            <tr key={item.couponId}>
              <td className="table-td__icon">
                <button
                  className="table-td__icon-btn"
                  onClick={() => couponId({ couponId: item.couponId })}
                >
                  <RiEditLine />
                </button>
              </td>
              <td>{item.couponCode}</td>
              <td>{item.couponType}</td>
              <td>{item.couponValue}</td>
              <td>{item.hasRedeemed}</td>
              <td>{FormatDateToLocaleDateString(item.expiryDate)}</td>
              <td>{FormatDateToLocaleDateString(item.createdAt)}</td>
              <td>{item.comment}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
