import React from "react";
import { FormatDateToLocaleDateString } from "../../../../../hooks/DateTime";
import "../../../../../components/styles/Table.css";

const Table = ({ data }) => {
  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th className="table-th__title">Title</th>
            <th>Video Id</th>
            <th>Uploaded</th>
            <th>Est. Earnings</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item) => (
            <tr key={item.videoId}>
              <td className="table-td__title">{item.title}</td>
              <td>{item.videoId}</td>
              <td>{FormatDateToLocaleDateString(item.uploadedAt)}</td>
              <td>{item.estimateEarnings.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
