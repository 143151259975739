import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import { Form } from "../../components/layout/layout/Layout";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../components/layout/container/Container";
import Axios from "../axios/Axios";
import { TextField } from "../../components/input/Inputs";
import { objectDataValidation } from "../../hooks/Validation";
import { CalculateDaysInAdvanceWithHours } from "../../hooks/DateTime";

const CheckoutForm = (props) => {
  const { item } = props;
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponValue, setCouponValue] = useState("");
  const [showPaymentCardFields, setShowPaymentCardFields] = useState(true);

  const [successMessage, setSuccessMessage] = useState("");
  const [successCoupon, setSuccessCoupon] = useState(false);
  const [disableCoupon, setDisableCoupon] = useState(false);

  const [noPaymentRequiredData, setNoPaymentRequiredData] = useState({});

  // Item Detail:
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [uploadedAt, setUploadedAt] = useState("");
  const [memberId, setMemberId] = useState("");
  const [videoId, setVideoId] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [couponId, setCouponId] = useState("");
  const [planType, setPlanType] = useState("");

  const [toggleCouponOption, setToggleCouponOption] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    updateItemValues();
    setCouponCode("");
  }, [stripe]);

  const updateItemValues = () => {
    setTitle(item.title);
    setPrice(item.price);
    setUploadedAt(item.uploadedAt);
    setMemberId(item.memberId);
    setVideoId(item.videoId);
    setPartnerId(item.partnerId);
    setPlanType(item.planType);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    if (error) {
      if (error.code === "card_declined") return setMessage(error.message);
      if (error.code === "incorrect_cvc") return setMessage(error.message);
      if (error.code === "expired_card") return setMessage(error.message);
      if (error.code === "processing_error") return setMessage(error.message);
      if (error.code === "incorrect_number") return setMessage(error.message);
      return setMessage("An unexpected error occurred");
    }

    if (paymentIntent) {
      if (paymentIntent.status === "succeeded") {
        return apiSavePaymentDetail(
          paymentDetailObject(
            paymentIntent.id,
            paymentIntent.amount,
            paymentIntent.status,
            paymentIntent.created
          )
        );
      }
    }
  };

  const apiSavePaymentDetail = (data) => {
    Axios.post("/api/save/payment-detail", data)
      .then((res) => {
        const { success, videoId, partnerId } = res.data;
        if (success) {
          return (window.location.href = `/watch/${videoId}/${partnerId}`);
        }
      })
      .catch((err) => console.error(err));
  };

  const paymentElementOptions = {
    layout: {
      type: "accordion",
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: true,
    },
  };

  const paymentDetailObject = (paymentId, amount, status, paymentDate) => {
    const rentalDuration = () => {
      if (planType === "PPV") return 48; //hours
    };

    const data = {
      stripePaymentId: paymentId,
      stripeAmount: amount,
      stripePaymentStatus: status,
      stripePaymentDate: paymentDate,
      title: title,
      uploadedAt: uploadedAt,
      planType: planType,
      rentalDuration: rentalDuration(),
      rentalPeriodEnd: CalculateDaysInAdvanceWithHours(
        paymentDate,
        rentalDuration()
      ),
      couponId: couponId,
      couponCode: couponCode,
      couponValue: couponValue,
      memberId: memberId,
      videoId: videoId,
      partnerId: partnerId,
    };

    return data;
  };

  const submitCouponCode = () => {
    const data = {
      videoId: item.videoId,
      partnerId: item.partnerId,
      couponCode: couponCode.trim(),
      amount: item.price,
      paymentIntentId: item.paymentIntentId,
    };

    if (!objectDataValidation(data)) {
      return alert("Enter your coupon code");
    }

    return apiProcessCouponCode(data);
  };

  const apiProcessCouponCode = (data) => {
    Axios.post("/validate/partner/coupon", data)
      .then((res) => {
        const item = res.data[0];

        if (!item.success) {
          setSuccessMessage(item.message);
          return setShowPaymentCardFields(true);
        }

        if (item.success && item.stripeAmount === "0") {
          setNoPaymentRequiredData(item);
          setPrice(item.stripeAmount);
          setCouponValue(item.couponValue);
          setCouponId(item.couponId);
          setDisableCoupon(true);
          setSuccessCoupon(true);
          setSuccessMessage(item.message);
          return setShowPaymentCardFields(false);
        }

        if (item.success && parseFloat(item.stripeAmount) > 0) {
          setPrice(item.stripeAmount);
          setCouponValue(item.couponValue);
          setCouponId(item.couponId);
          setDisableCoupon(true);
          setSuccessCoupon(true);
          setSuccessMessage(item.message);
          return setShowPaymentCardFields(true);
        }
      })
      .catch((err) => console.error(err));
  };

  const handlePaymentWithoutUsingStripe = () => {
    return apiSavePaymentDetail(
      paymentDetailObject(
        noPaymentRequiredData.stripePaymentId,
        noPaymentRequiredData.stripeAmount,
        noPaymentRequiredData.stripePaymentStatus,
        noPaymentRequiredData.stripePaymentDate
      )
    );
  };

  const handleToggleCouponOption = () => {
    setToggleCouponOption((open) => !open);
    return setCouponCode("");
  };

  return (
    <Main>
      <Content scroll={"scroll-Y"}>
        <ContentWrapper>
          <Form width={700} onSubmit={handleSubmit}>
            <div className="checkout_item-info__con">
              <span className="checkout__item-name">{item.title}</span>
              <span className="checkout_item__price">
                {price === "0" ? "FREE" : `AUD ${price}`}
              </span>
            </div>

            <label
              className="checkout__coupon-label"
              style={{
                display: "flex",
                marginBottom: !toggleCouponOption && 16,
              }}
            >
              Do you have a coupon?{" "}
              <button
                type="button"
                className="checkout_select-coupon__btn"
                onClick={handleToggleCouponOption}
              >
                {!toggleCouponOption ? "Yes" : "No"}
              </button>
            </label>

            {toggleCouponOption && (
              <>
                <TextField
                  disabled={disableCoupon}
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  placeholder="Enter your coupon code"
                  button={
                    <button
                      type="button"
                      style={{
                        width: 100,
                        textAlign: "center",
                        fontWeight: 600,
                        fontSize: 14,
                        borderRadius: 4,
                        marginLeft: 8,
                        cursor: "pointer",
                        background: "lightGrey",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        submitCouponCode();
                      }}
                    >
                      Apply
                    </button>
                  }
                  inputContainerWidth={"medium"}
                  errorMessage={successMessage}
                  messageColor={successCoupon}
                />

                <br />
              </>
            )}

            {!showPaymentCardFields && (
              <button
                type="button"
                className="checkout_submit__btn"
                onClick={() => {
                  handlePaymentWithoutUsingStripe();
                }}
              >
                <span id="button-text">
                  {isLoading ? (
                    <div className="spinner" id="spinner"></div>
                  ) : (
                    "START WATCHING"
                  )}
                </span>
              </button>
            )}

            {showPaymentCardFields && (
              <>
                <LinkAuthenticationElement
                  id="link-authentication-element"
                  options={{
                    defaultValues: { email: item.memberEmail },
                  }}
                />

                <PaymentElement
                  id="payment-element"
                  options={paymentElementOptions}
                />

                <button
                  className="checkout_submit__btn"
                  disabled={isLoading || !stripe || !elements}
                  id="submit"
                  style={{ marginBottom: 24 }}
                >
                  <span id="button-text">
                    {isLoading ? (
                      <div className="spinner" id="spinner"></div>
                    ) : (
                      "START WATCHING"
                    )}
                  </span>
                </button>
              </>
            )}

            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
          </Form>
          <br />
          <br />
        </ContentWrapper>
      </Content>
    </Main>
  );
};

export default CheckoutForm;
