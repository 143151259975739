import React from "react";
import { FormatDateToLocaleDateString } from "../../../../../hooks/DateTime";
import "../../../../../components/styles/Table.css";

const Table = ({ data }) => {
  const calculateStreamingFees = (totalEarnings) => {
    const rate = 30; //percent %
    const deductibleAmount = Number(totalEarnings / 100) * rate;
    return deductibleAmount.toFixed(2);
  };

  const calculatePayableAmount = (totalEarnings) => {
    const payableAmount =
      Number(totalEarnings) - calculateStreamingFees(totalEarnings);
    return payableAmount.toFixed(2);
  };

  const totalEarnings = (totalEarnings) => {
    return totalEarnings.toFixed(2);
  };

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th className="table-th__title transaction__title">Title</th>
            <th>Video Id</th>
            <th>Uploaded</th>
            <th>Payment Date</th>
            <th>Fees (30%)</th>
            <th>Payable $</th>
            <th>Total $</th>
            <th>Partner Email</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item) => (
            <tr key={item.videoId}>
              <td className="table-td__title">{item.title}</td>
              <td>{item.videoId}</td>
              <td>{FormatDateToLocaleDateString(item.uploadedAt)}</td>
              <td>{FormatDateToLocaleDateString(item.paymentDate)}</td>
              <td>{calculateStreamingFees(item.estimateEarnings)}</td>
              <td>{calculatePayableAmount(item.estimateEarnings)}</td>
              <td>{totalEarnings(item.estimateEarnings)}</td>
              <td>{item.partnerEmail}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
