import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Axios from "../../api/axios/Axios";
import { SportCard } from "../../components/cards/Cards";
import { RiSearch2Line } from "react-icons/ri";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../components/layout/container/Container";
import { ListGrid } from "../../components/layout/layout/Layout";
import NoDataDialog from "../../components/dialog/NoDataDialog";
import { Button } from "../../components/button/Buttons";
import {
  timestampToDatetimeDifference,
  CalculateTimeDifference,
} from "../../hooks/DateTime";
import "./SearchList.css";

const SearchList = ({ isLoggedIn, userType }) => {
  const [videoData, setVideosData] = useState([]);
  const [searchParams] = useSearchParams();
  const getQuery = searchParams.get("q");
  const [totalResults, setTotalResults] = useState(0);

  const navigateTo = useNavigate();

  useEffect(() => apiGetSearchResults(getQuery, 0), [getQuery]);

  const apiGetSearchResults = (value, offSet) => {
    Axios.get("/select/search/query", {
      params: { query: value, offSet: offSet },
    })
      .then((res) => {
        const data = res.data;
        if (offSet === 0) return setVideosData(data);
        setTotalResults(data[0].totalResults);
        return setVideosData([...videoData, ...data]);
      })
      .catch((err) => console.error(err));
  };

  // 📍Handle load more items:

  const handleLoadMoreOnClick = () => {
    const totalItemsLoaded = videoData.length;
    if (totalItemsLoaded >= totalResults) return;
    return apiGetSearchResults(getQuery, totalItemsLoaded);
  };

  const showLoadMoreButton = () => {
    if (totalResults > 30 && videoData.length !== totalResults) return true;
  };

  const handleChannelClicked = (userName) => {
    if (isLoggedIn && userType !== "Member")
      return alert("Member access only.");
    return navigateTo(`/${userName}`);
  };

  const handleSelectedMatchOnClick = (videoId, partnerId) => {
    return (window.location.href = `/watch/${videoId}/${partnerId}`);
  };

  const MatchList = () => {
    return (
      <>
        {videoData.length > 0 && (
          <>
            <span className="search-page__header">
              <RiSearch2Line style={{ fontSize: 24, marginRight: 8 }} />{" "}
              {getQuery}
            </span>

            <br />

            <ListGrid>
              {videoData.map((video, index) => (
                <SportCard
                  key={index}
                  title={video.title}
                  duration={video.duration}
                  thumbnail={video.thumbnail}
                  avatar={video.channelName}
                  channelName={video.channelName}
                  videoClicked={() => {
                    handleSelectedMatchOnClick(video.videoId, video.partnerId);
                  }}
                  createdAt={timestampToDatetimeDifference(video.createdAt)}
                  newLabelNotification={CalculateTimeDifference(
                    video.createdAt
                  )}
                  channelClicked={() => {
                    handleChannelClicked(video.userName);
                  }}
                />
              ))}
            </ListGrid>

            {showLoadMoreButton() && (
              <Button
                value="Load More"
                style={{ width: 250, margin: "48px auto" }}
                onClick={handleLoadMoreOnClick}
              />
            )}

            <br />
            <br />
          </>
        )}

        {videoData !== null && videoData.length === 0 && (
          <NoDataDialog
            title={"No results found"}
            message={"Try another search"}
          />
        )}
      </>
    );
  };

  return (
    <Main>
      <Content scroll={"scroll-Y"}>
        <ContentWrapper>
          <MatchList />
          <br />
          <br />
          <br />
        </ContentWrapper>
      </Content>
    </Main>
  );
};

export default SearchList;
