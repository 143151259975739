import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../../api/axios/Axios";
import {
  Form,
  InputStack,
  Heading,
} from "../../../components/layout/layout/Layout";
import { objectDataValidation } from "../../../hooks/Validation";
import { Button } from "../../../components/button/Buttons";
import { TextField } from "../../../components/input/Inputs";
import { Main } from "../../../components/layout/container/Container";
import "../../../components/styles/Form.css";

const MemberSignInPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const navigateTo = useNavigate();

  const handleSubmit = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(email)) {
      return alert("Invalid email format.");
    }

    const data = { email: email, password: password };

    if (!objectDataValidation(data))
      return alert("Please complete all required fields.");

    return apiMemberSignIn(data);
  };

  const apiMemberSignIn = (data) => {
    Axios.post("/api/member/sign-in", data)
      .then((res) => {
        const { success, message } = res.data;
        if (!success) return setMessage(message);
        return (window.location.href = "/");
      })
      .catch((err) => console.error(err));
  };

  const HandleKeyDown = (e) => e.keyCode === 13 && handleSubmit(e);

  const handlePasswordChange = (e) => setPassword(e.target.value);

  return (
    <Main>
      <Form width={500}>
        <Heading title={"Sign In as Member"} />

        <br />

        {message && (
          <div
            style={{
              color: "white",
              padding: 16,
              background: "#ff4040",
              textAlign: "center",
              marginBottom: 16,
              borderRadius: 8,
            }}
          >
            {message}
          </div>
        )}

        <InputStack>
          <TextField
            type="email"
            label="Email"
            value={email}
            placeholder="e.g. email@domain.com"
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => HandleKeyDown(e)}
          />

          <TextField
            type="password"
            label="Password"
            value={password}
            placeholder="********"
            onChange={handlePasswordChange}
            onKeyDown={(e) => HandleKeyDown(e)}
            showPasswordOption
          />

          <button
            type="button"
            onClick={() => navigateTo("/member/forgot-password")}
            className="access-form__forgot-password"
          >
            Forgot Password
          </button>
        </InputStack>

        <br />

        <Button
          value="Sign In"
          variant="fill"
          style={{ width: "100%" }}
          onClick={(e) => handleSubmit(e)}
        />

        <br />

        <span className="access-form__dont-have-an-account">
          Don't have an account?{" "}
          <button
            type="button"
            onClick={() => navigateTo("/member/sign-up")}
            className="access-form__link"
          >
            Create account
          </button>
        </span>

        <br />
      </Form>
    </Main>
  );
};

export default MemberSignInPage;
