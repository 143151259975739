import React, { useEffect, useState } from "react";
import { TextField, TextArea } from "../../../../../components/input/Inputs";
import { Button } from "../../../../../components/button/Buttons";
import Axios from "../../../../../api/axios/Axios";
import {
  InputStack,
  Form,
} from "../../../../../components/layout/layout/Layout";
import Heading from "../../../../../components/heading/Heading";
import { objectDataValidation } from "../../../../../hooks/Validation";
import { ConfirmedDialog } from "../../../../../components/dialog/Dialog";

const MAX_CHAR_LIMIT = 500;

const PartnerPersonalDetailForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [email, setEmail] = useState("");
  const [channelName, setChannelName] = useState("");
  const [userName, setUserName] = useState("");
  const [about, setAbout] = useState("");
  const [mainContact, setMainContact] = useState("");
  const [toggleAlert, setToggleAlert] = useState(false);
  const [charCount, setCharCount] = useState(MAX_CHAR_LIMIT);

  const [currentUserName, setCurrentUserName] = useState("");
  const [isUserNameValid, setIsUserNameValid] = useState(true);

  const [useNameValidationMessage, setUseNameValidationMessage] = useState("");

  useEffect(() => apiGetAccountDetails(), []);

  const apiGetAccountDetails = () => {
    Axios.get("/api/partner/select/account-detail")
      .then((res) => {
        const data = res.data[0];

        setFirstName(data.firstName);
        setLastName(data.lastName);
        setDateOfBirth(data.dateOfBirth);
        setEmail(data.email);
        setChannelName(data.channelName);
        setUserName(data.userName);
        setCurrentUserName(data.userName);
        setAbout(data.about);
        setMainContact(data.mainContact ? data.mainContact : "");
      })
      .catch((err) => console.error(err));
  };

  const handleSubmit = () => {
    const data = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      dateOfBirth: dateOfBirth.trim(),
      channelName: channelName.trim(),
      userName: userName.trim(),
      about: about.trim(),
      mainContact: mainContact.trim() ? mainContact.trim() : email.trim(),
    };

    if (!objectDataValidation(data) || !isUserNameValid)
      return alert("Please complete all required fields.");

    return apiUpdatePersonalDetails(data);
  };

  const apiUpdatePersonalDetails = (data) => {
    Axios.post("/api/partner/update/personal-detail", data)
      .then((res) => {
        const { success } = res.data;
        if (success) return setToggleAlert(true);
      })
      .catch((err) => console.error(err));
  };

  const handleFirstNameInput = (e) => {
    const value = e.target.value;
    return setFirstName(value);
  };

  const handleLastNameInput = (e) => {
    const value = e.target.value;
    return setLastName(value);
  };

  const handleChannelNameInput = (e) => {
    const value = e.target.value;
    if (value.length > 100) return;
    return setChannelName(value);
  };

  const handleUserNameInput = (e) => {
    const value = e.target.value.replace(/[\s']/g, "");
    if (value.length > 100) return;
    setUserName(value);
    return validateUsername(value);
  };

  const handleDateOfBirthInput = (e) => {
    const value = e.target.value;
    if (value.length > 10) return;
    return setDateOfBirth(value);
  };

  const handleMainContactInput = (e) => {
    const value = e.target.value;
    if (value.length > 100) return;
    return setMainContact(value);
  };

  const handleAboutInput = (e) => {
    const inputText = e.target.value;

    if (inputText.length <= MAX_CHAR_LIMIT) {
      setAbout(inputText);
      return setCharCount(MAX_CHAR_LIMIT - inputText.length);
    }
  };

  const handleAboutPaste = (e) => {
    e.preventDefault();
    const pasteText = e.clipboardData.getData("text");
    const currentText = about + pasteText;
    if (currentText.length <= MAX_CHAR_LIMIT) {
      setAbout(currentText);
      setCharCount(MAX_CHAR_LIMIT - currentText.length);
    } else {
      const truncatedText = pasteText.substring(
        0,
        MAX_CHAR_LIMIT - about.length
      );
      setAbout(about + truncatedText);
      setCharCount(0);
    }
  };

  const validateUsername = (username) => {
    if (username.length < 3) {
      setUseNameValidationMessage(
        "Username must be at least 3 characters long."
      );
      return setIsUserNameValid(false);
    } else if (/[^a-zA-Z0-9]/.test(username)) {
      setUseNameValidationMessage(
        "Username can only contain letters and numbers."
      );
      return setIsUserNameValid(false);
    }

    apiCheckIfUserNameIsTaken(username);
    return setIsUserNameValid(true);
  };

  const apiCheckIfUserNameIsTaken = (username) => {
    Axios.get("/api/partner/select/username", {
      params: { userName: username },
    }).then((res) => {
      const { success } = res.data;
      if (success) {
        setUseNameValidationMessage("Username is available");
        return setIsUserNameValid(true);
      } else {
        setUseNameValidationMessage("Username is not available");
        return setIsUserNameValid(false);
      }
    });
  };

  return (
    <Form>
      <Heading title={"Personal Details"} color={"black"} />

      <br />
      <br />

      <InputStack>
        <TextField
          type="text"
          label="First Name"
          value={firstName}
          onChange={handleFirstNameInput}
        />

        <TextField
          type="text"
          label="Last Name"
          value={lastName}
          onChange={handleLastNameInput}
        />

        <TextField
          type="date"
          label="Date Of Birth"
          value={dateOfBirth}
          data-date-format="DD-MM-YYYY"
          onChange={handleDateOfBirthInput}
        />

        <br />

        <TextField
          label={
            <>
              Channel Name <br />
              <span
                style={{
                  display: "flex",
                  fontSize: 12,
                  fontWeight: 400,
                  marginTop: 8,
                  color: "gray",
                }}
              >
                Your channel name is publicly visible
              </span>
            </>
          }
          value={channelName}
          onChange={(e) => handleChannelNameInput(e)}
        />

        <div className="texfield-validate-username__con">
          <TextField
            label={
              <>
                Username <br />
                <span
                  style={{
                    display: "flex",
                    fontSize: 12,
                    fontWeight: 400,
                    marginTop: 8,
                    color: "gray",
                  }}
                >
                  Your username is your XReplay channel's URL
                </span>
              </>
            }
            value={userName}
            onChange={handleUserNameInput}
          />
          <span
            style={{
              display: "flex",
              fontSize: 12,
              fontWeight: 400,
              marginTop: 4,
              color: isUserNameValid ? "green" : "red",
            }}
          >
            {currentUserName === userName ? "" : useNameValidationMessage}
          </span>
        </div>

        <div className="textarea-wrapper">
          <TextArea
            label="Tell your audience more about your channel"
            value={about}
            required
            onChange={handleAboutInput}
            onPaste={handleAboutPaste}
            placeholder="Write here.."
            maxHeight={200}
          />

          <span className="custom-textarea-char-count">
            {charCount} char. remaining
          </span>
        </div>

        <TextField
          type="text"
          label="Main Contact"
          value={mainContact}
          onChange={handleMainContactInput}
        />
      </InputStack>

      <br />

      <Button
        value="Update Details"
        variant="fill"
        onClick={() => handleSubmit()}
        style={{ width: "100%" }}
      />

      {toggleAlert && (
        <ConfirmedDialog
          status="success"
          message="Updated"
          open={setToggleAlert}
        />
      )}

      <br />
    </Form>
  );
};

export default PartnerPersonalDetailForm;
