import React from "react";
import "./SplashScreen.css";
import logo from "../../assets/images/logo64.png";

const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <div className="splash-screen__content">
        <div className="splash-screen_logo__wrapper">
          <img src={logo} alt="logo" />
        </div>

        {/* <h1>RUGBYGO</h1> */}
      </div>
    </div>
  );
};

export default SplashScreen;
