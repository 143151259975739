import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Watch.css";
import { FormatDateToLocaleDateString } from "../../hooks/DateTime";
import HorizontalVideoPlayer from "../../components/player/horizontal/HorizontalVideoPlayer";
import { Button } from "../../components/button/Buttons";
import { Main } from "../../components/layout/container/Container";
import Filters from "./components/Filters";
import { PiInfo } from "react-icons/pi";

const MatchPreviewPage = ({ data, isLoggedIn }) => {
  const [selectedPlanOption, setSelectedPlanOption] = useState("");
  const [showPlanOption, setShowPlanOption] = useState(false);

  const navigateTo = useNavigate();

  const handleChannelClicked = (userName) => {
    return navigateTo(`/${userName}`);
  };

  const togglePlanOptionPrompt = (e, plan) => {
    e.preventDefault();
    e.stopPropagation();

    setShowPlanOption((show) => !show);

    if (plan === "PPV") setSelectedPlanOption("PPV");
    if (plan === "SUBS") setSelectedPlanOption("SUBS");
  };

  const handleSelectedPlanOnClick = (e) => {
    const { videoId, partnerId } = data;
    const planType = e.target.value;

    const URL = `/member/payment/${videoId}/${partnerId}/${planType}`;
    return navigateTo(URL);
  };

  const MembershipPlanOptions = () => {
    return (
      <div className="watch_plan-options__con">
        <ul>
          <li>
            <label htmlFor="watch_plan-option__ppv">
              <input
                type="radio"
                name="planOption"
                id="watch_plan-option__ppv"
                value="PPV"
                onChange={(e) => handleSelectedPlanOnClick(e)}
              />
              Pay Per View
              <PiInfo
                className="watch_plan-option__icon"
                onClick={(e) => {
                  return togglePlanOptionPrompt(e, "PPV");
                }}
              />
            </label>

            <span className="watch_plan-option__price">
              ${data.twoDayPrice}
            </span>

            {showPlanOption && selectedPlanOption === "PPV" ? (
              <div className="watch_plan-option-info__prompt">
                PPV is a one-time fee to access this video for 48 hours after
                purchase.
              </div>
            ) : null}
          </li>

          <li style={{ color: "darkGrey" }}>
            <label htmlFor="watch_plan-option__subs">
              <input
                type="radio"
                name="planOption"
                id="watch_plan-option__subs"
                disabled
              />
              Subscription
            </label>

            <span className="watch_plan-option__price">Not available</span>

            {showPlanOption && selectedPlanOption === "SUBS" ? (
              <div className="watch_plan-option-info__prompt">
                Subscription not available.
              </div>
            ) : null}
          </li>
        </ul>
      </div>
    );
  };

  return (
    <Main>
      <div className="watch-now__container">
        <div className="watch-now__content">
          <div className="watch-now__player-wrapper">
            <HorizontalVideoPlayer
              videoUrl={data.videoUrl}
              previewDuration={30}
            />

            <span className="watch-now__preview-label">PREVIEW ONLY</span>
          </div>

          <div className="watch-now__detail-con">
            <span className="watch-now__title">{data.title}</span>

            <div className="watch-now_channel-info__con">
              <div className="watch-now__channel-info">
                <span
                  className="watch-now__channel-name"
                  onClick={() => handleChannelClicked(data.userName)}
                >
                  {data.channelName}
                </span>

                <span className="watch-now__video-count">
                  {data.totalVideos <= 1
                    ? data.totalVideos + " video"
                    : data.totalVideos + " videos"}
                </span>
              </div>
            </div>

            <MembershipPlanOptions />

            <Filters
              videoId={data.videoId}
              partnerId={data.partnerId}
              isLoggedIn={isLoggedIn}
            />

            <div className="watch-now__video-description">
              <span className="watch-now__match-date">
                {FormatDateToLocaleDateString(data.createdAt)}
              </span>

              <p className="watch-now__description">{data.description}</p>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />
      </div>
    </Main>
  );
};

export default MatchPreviewPage;
