import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

// Public pages:
import Header from "./components/layout/header/Header";
import HomePage from "./page/home/Home";
import SearchListPage from "./page/search/SearchList";
import ContactPage from "./page/support/SupportForm";
import SplashScreen from "./components/splashScreen/SplashScreen";
import PartnerChannelPage from "./page/channel/Channel";

// Match Replay Pages:
import WatchReplayPage from "./page/watch/WatchReplay";

// Access Pages:
import MemberSignUpPage from "./user/access/Member/MemberSignUp";
import MemberSignInPage from "./user/access/Member/MemberSignIn";
import PartnerSignUpPage from "./user/access/Partner/PartnerSignUp";
import PartnerSignInPage from "./user/access/Partner/PartnerSignIn";
import AdminSignUpPage from "./user/access/admin/SignUp";
import AdminSignInPage from "./user/access/admin/SignIn";
import ForgotPasswordPage from "./user/access/password/ForgotPassword";
import ResetPasswordPage from "./user/access/password/ResetPassword";

// Member Pages:
import MemberAccountPage from "./user/member/page/account/Account";
import MemberWatchlistPage from "./user/member/page/watchlist/Main";
import MemberPaymentPage from "./api/stripe/Payment";
import MemberSavedVideoPage from "./user/member/page/savedItems/SavedItems";

// Partner Pages:
import PartnerAccountPage from "./user/partner/page/account/Account";
import PartnerEarningsPage from "./user/partner/page/earnings/Earnings";
import PartnerMatchesPage from "./user/partner/page/matches/Matches";
import PartnerCouponsPage from "./user/partner/page/coupons/Coupons";
import PartnerAddVideoPage from "./user/partner/page/uploads/AddVideo";
import PartnerEditVideoPage from "./user/partner/page/uploads/EditVideo";

// Admin Pages:
import AdminAccountPage from "./user/admin/page/account/Account";
import AdminMatchesPage from "./user/admin/page/matches/Matches";
import AdminTransactionsPage from "./user/admin/page/transaction/Transaction";

// Error Pages:
import Error404Page from "./page/error/Error404";

import {
  MemberPrivateRoute,
  AdminPrivateRoute,
  PartnerPrivateRoute,
} from "./route/Protected";
import { CheckLoginStatus } from "./hooks/Auth";
import "./App.css";

const App = () => {
  const [showSplash, setShowSplash] = useState(false);
  const [isLoggedIn, setIsAuthenticated] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userType, setUserType] = useState(null);
  const [access, setAccess] = useState(null);

  const isLogged = CheckLoginStatus();

  useEffect(() => handleShowSplash(), []);

  useEffect(() => {
    if (isLogged !== null) {
      const { success, user, type, access } = isLogged;
      setIsAuthenticated(success);
      setUserName(user);
      setUserType(type);
      setAccess(access);
    }
  }, [isLogged]);

  const handleShowSplash = () => {
    const isSplashShownBefore = localStorage.getItem("splashShown");
    if (!isSplashShownBefore) {
      setShowSplash(true);
      localStorage.setItem("splashShown", true);
    }

    setTimeout(() => {
      setShowSplash(false);
    }, 3000);
  };

  return (
    <Router>
      <Header
        isLoggedIn={isLoggedIn}
        userName={userName}
        userType={userType}
        access={access}
      />

      {showSplash && <SplashScreen />}

      <Routes>
        {/* -------------------------------- */}
        {/* 📌 Public Pages: */}
        {/* -------------------------------- */}

        <Route
          path="/"
          element={<HomePage isLoggedIn={isLoggedIn} userType={userType} />}
        />

        <Route
          path="/search"
          element={
            <SearchListPage isLoggedIn={isLoggedIn} userType={userType} />
          }
        />

        <Route
          path="/:userName"
          element={
            <PartnerChannelPage isLoggedIn={isLoggedIn} userType={userType} />
          }
        />
        <Route
          path="/watch/:videoId/:partnerId"
          element={<WatchReplayPage isLoggedIn={isLoggedIn} />}
        />
        <Route path="/contact/support" element={<ContactPage />} />
        <Route path="/error/404" element={<Error404Page />} />

        {/* -------------------------------- */}
        {/* 📌 Partner Pages: */}
        {/* -------------------------------- */}

        <Route
          path="/partner/sign-in"
          element={!isLoggedIn ? <PartnerSignInPage /> : <Navigate to="/" />}
        />

        <Route
          path="/partner/sign-up"
          element={!isLoggedIn ? <PartnerSignUpPage /> : <Navigate to="/" />}
        />

        <Route
          path="/*"
          element={
            <PartnerPrivateRoute isLoggedIn={isLoggedIn} userType={userType} />
          }
        >
          <Route path="partner/account" element={<PartnerAccountPage />} />
          <Route path="partner/earnings" element={<PartnerEarningsPage />} />
          <Route path="partner/matches" element={<PartnerMatchesPage />} />
          <Route path="partner/coupons" element={<PartnerCouponsPage />} />
          <Route
            path="partner/upload/video"
            element={<PartnerAddVideoPage />}
          />
          <Route
            path="partner/edit/video/:videoId"
            element={<PartnerEditVideoPage />}
          />
        </Route>

        {/* -------------------------------- */}
        {/* 📌 Member Pages: */}
        {/* -------------------------------- */}

        <Route
          path="/member/sign-up"
          element={!isLoggedIn ? <MemberSignUpPage /> : <Navigate to="/" />}
        />

        <Route
          path="/member/sign-in"
          element={!isLoggedIn ? <MemberSignInPage /> : <Navigate to="/" />}
        />

        <Route
          path="/:user/forgot-password"
          element={!isLoggedIn ? <ForgotPasswordPage /> : <Navigate to="/" />}
        />

        <Route
          path="/reset-password/:user/:email/:token"
          element={!isLoggedIn ? <ResetPasswordPage /> : <Navigate to="/" />}
        />

        <Route
          path="/*"
          element={
            <MemberPrivateRoute isLoggedIn={isLoggedIn} userType={userType} />
          }
        >
          <Route path="member/account" element={<MemberAccountPage />} />
          <Route path="member/watchlist" element={<MemberWatchlistPage />} />
          <Route
            path="member/saved-videos"
            element={<MemberSavedVideoPage />}
          />

          <Route
            path="member/payment/:videoId/:partnerId/:planType"
            element={<MemberPaymentPage />}
          />
        </Route>

        {/* -------------------------------- */}
        {/* 📌 Admin Pages: */}
        {/* -------------------------------- */}

        <Route
          path="/admin/sign-up"
          element={
            !isLoggedIn ? (
              <AdminSignUpPage />
            ) : (
              <Navigate to="/admin/dashboard" />
            )
          }
        />
        <Route
          path="/admin/sign-in"
          element={
            !isLoggedIn ? (
              <AdminSignInPage />
            ) : (
              <Navigate to="/admin/dashboard" />
            )
          }
        />
        <Route
          path="/*"
          element={
            <AdminPrivateRoute isLoggedIn={isLoggedIn} userType={userType} />
          }
        >
          <Route path="admin/matches" element={<AdminMatchesPage />} />
          <Route path="admin/transaction" element={<AdminTransactionsPage />} />
          <Route path="admin/account" element={<AdminAccountPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
