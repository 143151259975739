import React, { useEffect, useState } from "react";
import Axios from "../../../../../api/axios/Axios";
import Table from "../components/Table";
import "./View.css";

const PurchaseHistoryList = () => {
  const [purchasedHistoryData, setPurchasedHistoryData] = useState([]);

  useEffect(() => apiGetPurchaseHistoryList(), []);

  const apiGetPurchaseHistoryList = () => {
    Axios.get("/api/member/select/purchase-history", {
      params: { offSet: 0 },
    })
      .then((res) => {
        const data = res.data;
        return setPurchasedHistoryData(data);
      })
      .catch((err) => console.error(err));
  };

  return <Table data={purchasedHistoryData} />;
};

export default PurchaseHistoryList;
