import React from "react";

const ContentWrapper = ({ children, width, position, maxWidth }) => {
  const togglePosition = () => {
    if (position === "right") return "0 0 0 auto";
    if (position === "left") return "0 auto 0 0";
    if (position === "center") return "0 auto 0 auto";

    return "0 auto 0 auto";
  };

  return (
    <div
      className={`custom__content-wrapper ${maxWidth}`}
      style={{
        width: !width ? 1200 : width,
        display: "flex",
        flexDirection: "column",
        margin: togglePosition(),
      }}
    >
      {children}
    </div>
  );
};

export default ContentWrapper;
