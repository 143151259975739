import React, { useRef, useState } from "react";
import { RiUpload2Fill } from "react-icons/ri";
import "./SelectVideoAndImage.css";
import { TbCaptureFilled } from "react-icons/tb";

const SelectVideoAndImage = ({
  getVideoFileObject,
  getThumbnailFileObject,
  getVideoDuration,
}) => {
  const [videoURL, setVideoURL] = useState(null);
  const [thumbnailURL, setThumbnailURL] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const handleVideoInputChange = (event) => {
    const file = event.target.files[0];
    return handleVideoFileValidation(file);
  };

  const handleVideoFileValidation = (file) => {
    if (!file) return false;

    const fileExtension = file.name.split(".")[1];

    if (file.type !== "video/mp4" || fileExtension === "MP4") {
      const msg =
        "Unsupported file type. Only video files in .mp4 format are accepted.";
      alert(msg);
      return setVideoURL(null);
    }

    const videoFileObject = file;
    const videoURL = URL.createObjectURL(videoFileObject);

    setVideoURL(videoURL);
    getVideoFileObject(videoFileObject);
  };

  const handleSelectFrameFromVideo = (e) => {
    e.preventDefault();

    if (!videoURL) return false;

    const video = videoRef.current;
    imageResizer(video, 480, 854);
  };

  const imageResizer = (img, maxWidth, maxHeight) => {
    const canvas = document.createElement("canvas");

    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    canvas.width = img.videoWidth;
    canvas.height = img.videoHeight;

    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    // Resize the captured frame

    let width = canvas.width;
    let height = canvas.height;

    if (width > maxWidth) {
      height *= maxWidth / width;
      width = maxWidth;
    }

    if (height > maxHeight) {
      width *= maxHeight / height;
      height = maxHeight;
    }

    // Create a new canvas for the resized image
    const resizedCanvas = document.createElement("canvas");
    const resizedCtx = resizedCanvas.getContext("2d");

    resizedCanvas.width = width;
    resizedCanvas.height = height;

    // Draw the resized image onto the new canvas

    resizedCtx.drawImage(
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      0,
      0,
      width,
      height
    );

    // Generate new filename:

    const timestamp = new Date().getTime();
    const randomString = Math.random().toString(36).substring(7);
    const newFilename = `${timestamp}${randomString}.jpeg`;
    const fileType = "image/jpeg";

    canvasToFile(resizedCanvas, newFilename, fileType);
  };

  const canvasToFile = (canvas, filename, fileType) => {
    const base64String = canvas.toDataURL(fileType);

    const parts = base64String.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const data = atob(parts[1]);
    const arrayBuffer = new ArrayBuffer(data.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < data.length; i++) {
      uint8Array[i] = data.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: contentType });
    const file = new File([blob], filename, { type: contentType });

    handleImageFileValidation(file);
  };

  const handleImageFileValidation = (file) => {
    if (!file) return;

    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

    if (!allowedExtensions.exec(file.name))
      return alert("Invalid file type. Please upload a valid image file.");

    const maxByte = 500000; /*max 500kb */
    if (file.size > maxByte) {
      const msg =
        "File size exceeds the limit of 500kb.\nWe recommend setting the image dimensions to (300 x 150) for thumbnail.";
      alert(msg);
      return setThumbnailURL(null);
    }

    const thumbnailFileObject = file;
    const thumbnailURL = URL.createObjectURL(thumbnailFileObject);

    setThumbnailURL(thumbnailURL);
    getThumbnailFileObject(thumbnailFileObject);
  };

  const handleVideoLoadedMetadata = () => {
    const currentDuration = videoRef.current.duration;

    const convertToMinutes = () => {
      const minutes = Math.floor(currentDuration / 60);
      const remainingSeconds = currentDuration % 60;
      return `${minutes}:${remainingSeconds.toFixed(0).padStart(2, "0")}`;
    };

    getVideoDuration(convertToMinutes());
  };

  return (
    <div className="custom-video-n-image__container">
      {/* Select Video:::: */}

      <label
        className="custom-video-n-image_video__wrapper"
        htmlFor="custom-video-n-image_video__input"
      >
        {!videoURL ? (
          <div className="custom-video-n-image_video-placeholder__wrapper">
            <RiUpload2Fill className="custom-video-n-image_img-placeholder__icon" />
            Upload Video
          </div>
        ) : (
          <video
            width="100%"
            height="100%"
            controls
            ref={videoRef}
            onLoadedMetadata={handleVideoLoadedMetadata}
          >
            <source src={videoURL} type="video/mp4" />
          </video>
        )}

        <input
          id="custom-video-n-image_video__input"
          style={{ display: "none" }}
          type="file"
          accept="video/*"
          onChange={handleVideoInputChange}
        />
      </label>

      {/* Select Thumbnail:::: */}

      <div className="custom-video-n-image_thumbnail__con">
        <div className="custom-video-n-image_img__wrapper">
          <button
            type="button"
            className="custom-video-n-image_thumbnail__btn"
            onClick={handleSelectFrameFromVideo}
          >
            <TbCaptureFilled className="custom-video-n-image_img-placeholder__icon" />
            <p>Capture Thumbnail</p>
          </button>

          {thumbnailURL && (
            <img
              src={thumbnailURL}
              alt="Selected Thumbnail"
              style={{ maxWidth: "100%" }}
            />
          )}
        </div>

        <canvas ref={canvasRef} style={{ display: "none" }} />
      </div>
    </div>
  );
};

export default SelectVideoAndImage;
