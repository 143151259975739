import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./Checkout";
import Axios from "../axios/Axios";
import "./Form.css";

const stripePublicKey = () => {
  if (process.env.NODE_ENV.trim() === "development") {
    return process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST;
  }
  return process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_LIVE;
};

const stripePromise = loadStripe(stripePublicKey());

const PaymentForm = () => {
  const [clientSecret, setClientSecret] = useState("");
  const [videoData, setVideoData] = useState({});

  const { videoId, partnerId, planType } = useParams();

  useEffect(() => handleCreatePaymentIntent(), [videoId, planType]);

  const handleCreatePaymentIntent = () => {
    const data = { videoId: videoId, partnerId: partnerId, planType: planType };

    const obj = Object.values(data).every((value) => !!value);
    if (!obj) return console.error("Invalid data");
    return apiCreatePaymentIntentOnLoad(data);
  };

  const apiCreatePaymentIntentOnLoad = (data) => {
    Axios.post("/api/create-payment-intent", data)
      .then((data) => {
        const video = data.data;
        setVideoData(video);
        return setClientSecret(video.clientSecret);
      })
      .catch((err) => console.error(err));
  };

  const appearance = { theme: "stripe" };
  const options = { clientSecret, appearance };

  return (
    <div className="PaymentForm">
      {videoData.clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            item={{
              paymentIntentId: videoData.paymentIntentId,
              title: videoData.title,
              price: videoData.price,
              description: videoData.description,
              uploadedAt: videoData.uploadedAt,
              planType: videoData.planType,
              videoId: videoData.videoId,
              memberId: videoData.memberId,
              partnerId: videoData.partnerId,
              memberEmail: videoData.memberEmail,
            }}
          />
        </Elements>
      )}
    </div>
  );
};

export default PaymentForm;
