import React, { useEffect, useState } from "react";
import { ConfirmedDialog } from "../../../../../components/dialog/Dialog";
import Axios from "../../../../../api/axios/Axios";
import {
  Form,
  InputStack,
  Heading,
} from "../../../../../components/layout/layout/Layout";
import { TextField } from "../../../../../components/input/Inputs";
import { Button } from "../../../../../components/button/Buttons";

function MemberPasswordForm({ passMemberId }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [toggleAlert, setToggleAlert] = useState(false);

  useEffect(() => apiGetMemberEmail(), []);

  const apiGetMemberEmail = () => {
    Axios.get("/select/member/email")
      .then((res) => {
        const member = res.data[0];
        passMemberId(member.memberId);
        return setEmail(member.email);
      })
      .catch((err) => console.error(err));
  };

  const handleSubmit = () => {
    if (password.length < 8 || password === "".trim())
      return alert("Password must be at least 8 characters long.");
    const data = { password: password.trim() };
    return apiUpdatePassword(data);
  };

  const apiUpdatePassword = (data) => {
    Axios.post("/update/member/password", data)
      .then((res) => {
        const success = res.data.success;
        if (success) return setToggleAlert(true);
      })
      .catch((err) => console.error(err));
  };

  // 📌 Handle input data:

  const handlePasswordInput = (e) => setPassword(e.target.value);

  return (
    <Form>
      <Heading title={"Update Password"} />

      <br />

      <InputStack>
        <TextField label="Email" value={email} disabled />

        <TextField
          type="password"
          label="Password"
          value={password}
          placeholder="Must be at least 8 characters."
          onChange={handlePasswordInput}
        />
      </InputStack>

      <br />

      <Button
        style={{ width: "100%" }}
        value="Update Password"
        variant="fill"
        onClick={handleSubmit}
      />

      <br />

      {toggleAlert && (
        <ConfirmedDialog message="Updated" open={setToggleAlert} />
      )}
    </Form>
  );
}

export default MemberPasswordForm;
