import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import ReactGA from "react-ga4";
ReactGA.initialize("G-HQC9S3515K");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

reportWebVitals();
