import React from "react";
import AdminTransactionList from "./view/TransactionList";
import "./Transaction.css";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../../../components/layout/container/Container";

const AdminTransactionsPage = () => {
  return (
    <Main>
      <Content scroll={"scroll-Y"}>
        <ContentWrapper >
          <AdminTransactionList />
          <br />
          <br />
        </ContentWrapper>
      </Content>
    </Main>
  );
};

export default AdminTransactionsPage;
