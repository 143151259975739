import React, { useState } from "react";
import Filters from "./components/Filters";
import HistoryList from "./view/HistoryList";
import MemberWatchList from "./view/Watchlist";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../../../components/layout/container/Container";
import Heading from "../../../../components/heading/Heading";

const MemberWatchlistPage = () => {
  const [visibility, setVisibility] = useState("Active");

  const toggleVisibility = (status) => {
    return setVisibility(status);
  };

  return (
    <Main>
      <Content scroll={"scroll-Y"}>
        <ContentWrapper >
          <Heading title={"My Watchlist"} />

          <br />

          <Filters
            visibility={visibility}
            activeOnClick={() => toggleVisibility("Active")}
            purchasedHistoryOnClick={() => toggleVisibility("History")}
          />

          <br />

          {visibility === "Active" && <MemberWatchList />}
          {visibility === "History" && <HistoryList />}

          <br />
        </ContentWrapper>
      </Content>
    </Main>
  );
};

export default MemberWatchlistPage;
