import React from "react";

const Filters = ({
  personalDetailFormOnClick,
  bankingFormOnClick,
  passwordFormOnClick,
  deleteAccountOnClick,
  visibility,
}) => {
  return (
    <ul className="main-filters__ul">
      <li
        className={visibility === "personalDetailForm" ? "active" : null}
        onClick={() => personalDetailFormOnClick()}
      >
        Personal Details
      </li>

      <li
        className={visibility === "bankingForm" ? "active" : null}
        onClick={() => bankingFormOnClick()}
      >
        Banking Details
      </li>

      <li
        className={visibility === "passwordForm" ? "active" : null}
        onClick={() => passwordFormOnClick()}
      >
        Change Password
      </li>

      <li
        className="filter-delete-btn"
        style={{ marginLeft: "auto", background: "none" }}
        onClick={() => deleteAccountOnClick()}
      >
        Delete Account
      </li>
    </ul>
  );
};

export default Filters;
