import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../../../api/axios/Axios";
import {
  Form,
  InputStack,
  Heading,
} from "../../../components/layout/layout/Layout";
import { Button } from "../../../components/button/Buttons";
import { Main } from "../../../components/layout/container/Container";
import { TextField } from "../../../components/input/Inputs";
import { PiXCircleBold } from "react-icons/pi";

const ResetPassword = () => {
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [validationErrors, setValidationErrors] = useState([]);
  const [isTokenValid, setIsTokenValid] = useState(false);

  const { user, email, token } = useParams();

  const navigateTo = useNavigate();

  useEffect(() => apiValidateToken(), []);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    validatePassword(password, newConfirmPassword);
  };

  const validatePassword = (newPassword, newConfirmPassword) => {
    const minLength = 8;

    const errors = [];

    if (newPassword.length < minLength)
      errors.push("Password must be at least 8 characters long");

    if (newPassword !== newConfirmPassword)
      errors.push("Passwords do not match");

    if (errors.length === 0) {
      setIsPasswordValid(true);
    } else setIsPasswordValid(false);

    return setValidationErrors(errors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isPasswordValid && token) {
      return apiUpdatePassword();
    } else return alert(validationErrors[0]);
  };

  const apiValidateToken = () => {
    const tokenObj = {
      email: email,
      token: token,
      userType: user,
    };

    Axios.post("/api/password/token", tokenObj)
      .then((res) => {
        const { success } = res.data;
        if (success) return setIsTokenValid(true);
      })
      .catch((err) => console.error(err));
  };

  const apiUpdatePassword = () => {
    const data = {
      email: email,
      password: password,
      token: token,
      userType: user,
    };

    Axios({
      method: "POST",
      type: "json",
      url: "/api/reset-password",
      data: data,
    })
      .then((res) => {
        const { success, userType } = res.data;
        if (success && userType === "Member") navigateTo("/member/sign-in");
        if (success && userType === "Partner") navigateTo("/partner/sign-in");
        if (success && userType === "Admin") navigateTo("/admin/sign-in");
      })
      .catch((err) => console.error(err));
  };

  const HandleKeyDown = (e) => e.keyCode === 13 && handleSubmit(e);

  const LinkExpiredPrompt = () => {
    return (
      <div className="reset-password-form_expired__con">
        <PiXCircleBold className="reset-password-form_expired__icon" />

        <span className="reset-password-form_expired__title">Link Expired</span>
        <p className="reset-password-form__message">
          To reset your password, return to the login page and select "Forgot
          Password" to send a new email.
        </p>
      </div>
    );
  };

  return (
    <Main>
      <Form width={500}>
        {isTokenValid ? (
          <>
            <Heading title={"Reset Password"} />

            <br />

            <InputStack>
              <TextField
                type="password"
                label="New Password"
                onChange={(e) => handlePasswordChange(e)}
                onKeyDown={(e) => HandleKeyDown(e)}
                placeholder="Password must be at least 8 characters long"
              />

              <TextField
                type="password"
                label="Confirm Password"
                onChange={(e) => handleConfirmPasswordChange(e)}
                onKeyDown={(e) => HandleKeyDown(e)}
                showPasswordOption
              />
            </InputStack>

            <br />

            <Button
              value="Reset Password"
              style={{ width: "100%" }}
              onClick={(e) => handleSubmit(e)}
            />
          </>
        ) : (
          <LinkExpiredPrompt />
        )}
      </Form>

      <br />
      <br />
    </Main>
  );
};

export default ResetPassword;
