import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  PiHouseSimpleBold,
  PiTagBold,
  PiCirclesFourBold,
  PiUserListBold,
  PiDiamondsFourBold,
  PiSignOutBold,
  PiMagnifyingGlassBold,
  PiUserCircleBold,
  PiCurrencyCircleDollarBold,
  PiVideoBold,
  PiCurrencyDollarBold,
  PiBellBold,
  PiBookmarkSimpleBold,
} from "react-icons/pi";
import { RiVideoAddLine } from "react-icons/ri";
import logo from "../../../assets/images/logo64.png";
import Axios from "../../../api/axios/Axios";
import { SlidingSearchBar } from "../search/Search";
import { timestampToDatetimeDifference } from "../../../hooks/DateTime";
import "./Header.css";

const Header = ({ isLoggedIn, userType, userName }) => {
  const [notificationData, setNotificationData] = useState([]);
  const [openSearchBar, setOpenSearchBar] = useState(false);
  const [userInitial, setUserInitial] = useState("");

  const [toggleAvatarDropmenu, setToggleAvatarDropmenu] = useState(false);
  const [toggleNotification, setToggleNotification] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);

  const navigateTo = useNavigate();

  const menuRef = useRef(null);
  const handleAvatarOnClick = () => setToggleAvatarDropmenu((open) => !open);
  const handleSignOut = () => apiSignOut();

  useEffect(() => {
    if (isLoggedIn === true) {
      getUserInitial();
      return apiGetNotification();
    }
  }, [isLoggedIn]);

  const getUserInitial = () => {
    if (isLoggedIn === true) {
      const initial = userName.charAt(0);
      return setUserInitial(initial);
    }
  };

  const apiGetNotification = () => {
    Axios.get("/select/new-match/notification")
      .then((res) => {
        const data = res.data;
        countNotificationUnReadOccurrences(data);
        return setNotificationData(data);
      })
      .catch((err) => console.error(err));
  };

  const countNotificationUnReadOccurrences = (data) => {
    const count = data.reduce((acc, curr) => {
      if (curr.hasRead === "0") return acc + 1;
      return acc;
    }, 0);
    return setNotificationCount(count);
  };

  const apiSignOut = () => {
    Axios.get("/api/sign-out")
      .then((res) => {
        const success = res.data.success;
        if (success) return (window.location.href = "/");
      })
      .catch((err) => console.error(err));
  };

  // ----------
  // 📌Public:
  // ----------

  const UnAuthorisedNavbar = () => {
    const handleSignInAsPartner = () => {
      navigateTo("/partner/sign-in");
      return handleAvatarOnClick();
    };

    const handleSignInAsMember = () => {
      navigateTo("/member/sign-in");
      return handleAvatarOnClick();
    };

    const MenuOptions = () => {
      return (
        <div className="navbar-dropmenu__con">
          <ul className="navbar-dropmenu__ul">
            <li onClick={handleSignInAsMember}>
              <PiUserCircleBold className="navbar-dropmenu__icon" />
              Sign In as Member
            </li>

            <li onClick={handleSignInAsPartner}>
              <PiUserCircleBold className="navbar-dropmenu__icon" />
              Sign In as Partner
            </li>
          </ul>
        </div>
      );
    };

    return (
      <>
        <button
          ref={menuRef}
          className="navbar-main-links__btn"
          onClick={(e) => {
            e.stopPropagation();
            handleAvatarOnClick();
          }}
        >
          <PiCirclesFourBold className="navbar-main-links__icon" />
        </button>

        {toggleAvatarDropmenu && <MenuOptions />}
      </>
    );
  };

  // ----------
  // 📌Member:
  // ----------

  const MemberNavbar = () => {
    const handleWatchList = () => {
      navigateTo("member/watchlist");
      return handleAvatarOnClick();
    };

    const handleAccount = () => {
      navigateTo("member/account");
      return handleAvatarOnClick();
    };

    const handleNotification = () => {
      if (notificationCount > 0) apiPostReadNotification();
      return setToggleNotification((open) => !open);
    };

    const apiPostReadNotification = () => {
      Axios.post("/save/has-read/notification", notificationData)
        .then((res) => {
          const { success } = res.data;

          if (success) return setNotificationCount(0);
        })
        .catch((err) => console.error(err));
    };

    const handleSavedVideos = () => {
      navigateTo("member/saved-videos");
      return handleAvatarOnClick();
    };

    const MenuOptions = () => {
      return (
        <div className="navbar-dropmenu__con">
          <div
            style={{
              padding: 16,
              display: "flex",
              flexDirection: "column",
              borderBottom: "solid 1px lightGrey",
            }}
            className="navbar-avatar_dropmenu-account__con"
          >
            <p style={{ fontWeight: 700, color: "#000" }}>Member</p>
            <p style={{ color: "grey", fontSize: "14px" }}>{userName}</p>
          </div>

          <ul className="navbar-dropmenu__ul">
            <li onClick={handleWatchList}>
              <PiVideoBold className="navbar-dropmenu__icon" /> My Watchlist
            </li>

            <li onClick={handleSavedVideos}>
              <PiBookmarkSimpleBold className="navbar-dropmenu__icon" />
              Saved Videos
            </li>

            <li onClick={handleAccount}>
              <PiUserListBold className="navbar-dropmenu__icon" />
              Account
            </li>

            <li onClick={handleSignOut}>
              <PiSignOutBold className="navbar-dropmenu__icon" /> Sign Out
            </li>
          </ul>
        </div>
      );
    };

    const handleSelectedMatchOnClick = (videoId, partnerId) => {
      return navigateTo(`/watch/${videoId}/${partnerId}`);
    };

    const NotificationDropmenu = () => {
      return (
        <div className="navbar-dropmenu__con navbar-dropmenu_notification__wrapper">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            className="navbar-avatar_dropmenu-account__con"
          >
            <span
              style={{
                fontWeight: 700,
                color: "#000",
                textAlign: notificationData.length === 0 ? "center" : "left",
              }}
            >
              {notificationData.length === 0
                ? "You have no notification"
                : "Notification"}
            </span>
          </div>

          <ul
            className="navbar-dropmenu__ul navbar-dropmenu_notification__ul"
            style={{ marginTop: 8 }}
          >
            {notificationData.map((item) => {
              return (
                <li
                  key={item.notificationId}
                  onClick={() =>
                    handleSelectedMatchOnClick(item.videoId, item.partnerId)
                  }
                >
                  <p className="notification-title">{item.title}</p>

                  <div className="notification-post-meta__wrapper">
                    <p className="notification-channel-name">
                      {item.channelName}
                    </p>
                    <p className="notification-created-at">
                      {timestampToDatetimeDifference(item.createdAt)}
                    </p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      );
    };

    return (
      <>
        <button
          className="navbar-notification__btn"
          ref={menuRef}
          onClick={(e) => {
            e.stopPropagation();
            handleNotification();
          }}
        >
          <PiBellBold className="navbar-main-links__icon" />

          {notificationCount > 0 && (
            <div
              className="navbar-main-link_notification__counter"
              style={{ color: "white" }}
            >
              {notificationCount}
            </div>
          )}
        </button>

        {toggleNotification && <NotificationDropmenu />}

        <button
          className="navbar-avatar__btn"
          ref={menuRef}
          onClick={(e) => {
            e.stopPropagation();
            handleAvatarOnClick();
          }}
        >
          {userInitial}
        </button>

        {toggleAvatarDropmenu && <MenuOptions />}
      </>
    );
  };

  // ---------
  // 📌Admin:
  // ---------

  const AdminNavbar = () => {
    const handleDashboardOnClick = () => {
      navigateTo("/admin/matches");
      return handleAvatarOnClick();
    };

    const handleTransactionsOnClick = () => {
      navigateTo("/admin/transaction");
      return handleAvatarOnClick();
    };

    const handleAccountOnClick = () => {
      navigateTo("/admin/account");
      return handleAvatarOnClick();
    };

    const MenuOptions = () => {
      return (
        <div className="navbar-dropmenu__con">
          <div
            style={{
              padding: 16,
              display: "flex",
              flexDirection: "column",
              borderBottom: "solid 1px lightGrey",
            }}
            className="navbar-avatar_dropmenu-account__con"
          >
            <span style={{ color: "#000", fontWeight: 700 }}>Admin</span>
            <span style={{ color: "grey", fontSize: "12px", fontWeight: 300 }}>
              {userName}
            </span>
          </div>

          <ul className="navbar-dropmenu__ul">
            <li onClick={handleDashboardOnClick}>
              <PiDiamondsFourBold className="navbar-dropmenu__icon" /> Matches
            </li>

            <li onClick={handleTransactionsOnClick}>
              <PiCurrencyDollarBold className="navbar-dropmenu__icon" />
              Transaction
            </li>

            <li onClick={handleAccountOnClick}>
              <PiUserListBold className="navbar-dropmenu__icon" />
              Account
            </li>

            <li onClick={handleSignOut}>
              <PiSignOutBold className="navbar-dropmenu__icon" /> Sign Out
            </li>
          </ul>
        </div>
      );
    };

    return (
      <>
        <button
          type="button"
          className="navbar-main-links__btn navbar-avatar__btn"
          ref={menuRef}
          onClick={(e) => {
            e.stopPropagation();
            handleAvatarOnClick();
          }}
        >
          {userInitial}
        </button>

        {toggleAvatarDropmenu && <MenuOptions />}
      </>
    );
  };

  // -----------
  // 📌Partner:
  // -----------

  const PartnerNavbar = () => {
    const handleUploadVideoOnClick = (e) => {
      e.stopPropagation();
      return navigateTo("/partner/upload/video");
    };

    const handleAccountOnClick = () => {
      navigateTo("/partner/account");
      return handleAvatarOnClick();
    };

    const handleMatchesOnClick = () => {
      navigateTo("/partner/matches");
      return handleAvatarOnClick();
    };

    const handleEarningsOnClick = () => {
      navigateTo("/partner/earnings");
      return handleAvatarOnClick();
    };

    const handleCouponsOnClick = () => {
      navigateTo("/partner/coupons");
      return handleAvatarOnClick();
    };

    const MenuOptions = () => {
      return (
        <div className="navbar-dropmenu__con">
          <div
            style={{
              padding: 16,
              display: "flex",
              flexDirection: "column",
              borderBottom: "solid 1px lightGrey",
            }}
            className="navbar-avatar_dropmenu-account__con"
          >
            <p style={{ color: "#000", fontWeight: 700 }}>Partner</p>
            <p style={{ color: "grey", fontSize: "12px" }}>{userName}</p>
          </div>

          <ul className="navbar-dropmenu__ul">
            <li onClick={handleMatchesOnClick}>
              <PiVideoBold className="navbar-dropmenu__icon" /> Matches
            </li>

            <li onClick={handleEarningsOnClick}>
              <PiCurrencyCircleDollarBold className="navbar-dropmenu__icon" />
              Earnings
            </li>

            <li onClick={handleCouponsOnClick}>
              <PiTagBold className="navbar-dropmenu__icon" />
              Coupons
            </li>

            <li onClick={handleAccountOnClick}>
              <PiUserListBold className="navbar-dropmenu__icon" /> Account
            </li>

            <li onClick={handleSignOut}>
              <PiSignOutBold className="navbar-dropmenu__icon" /> Sign Out
            </li>
          </ul>
        </div>
      );
    };

    return (
      <>
        <RiVideoAddLine
          onClick={handleUploadVideoOnClick}
          className="navbar__icon"
        />

        <button
          className="navbar-main-links__btn navbar-avatar__btn"
          ref={menuRef}
          onClick={(e) => {
            e.stopPropagation();
            handleAvatarOnClick();
          }}
        >
          {userInitial}
        </button>

        {toggleAvatarDropmenu && <MenuOptions />}
      </>
    );
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const handleClickOutside = (e) => {
    const elementReference = menuRef.current.className;
    const eventTarget = e.target.parentNode.className;

    if (elementReference !== eventTarget) {
      setOpenSearchBar(false);
      setToggleNotification(false);
      setToggleAvatarDropmenu(false);
      return;
    }
  };

  return (
    <header>
      <nav>
        <Link
          className="navbar-logo__con g_textLink g_disabledHover"
          onClick={() => (window.location.href = "/")}
        >
          <img src={logo} alt="logo" /> <span>XREPLAY</span>
        </Link>

        <div className="navbar-main-navigation__con">
          <button
            type="button"
            className="navbar-main-links__btn"
            onClick={(e) => {
              e.stopPropagation();
              setOpenSearchBar((open) => !open);
            }}
          >
            <PiMagnifyingGlassBold className="navbar-main-links__icon" />
          </button>

          <button
            type="button"
            className="navbar-main-links__btn"
            onClick={() => navigateTo("/")}
          >
            <PiHouseSimpleBold className="navbar-main-links__icon" />
          </button>

          {!isLoggedIn && <UnAuthorisedNavbar />}
          {isLoggedIn && userType === "Member" && <MemberNavbar />}
          {isLoggedIn && userType === "Admin" && <AdminNavbar />}
          {isLoggedIn && userType === "Partner" && <PartnerNavbar />}
        </div>
      </nav>

      {openSearchBar && <SlidingSearchBar isOpen={setOpenSearchBar} />}
    </header>
  );
};

export default Header;
