import React from "react";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../../../components/layout/container/Container";
import EarningsList from "./view/EarningsList";

const PartnerEarningsPage = () => {
  return (
    <Main>
      <Content>
        <ContentWrapper>
          <EarningsList />
        </ContentWrapper>
      </Content>
    </Main>
  );
};

export default PartnerEarningsPage;
