import React from "react";
import loading from "../../assets/gif/loading.gif";

const LoadingDialog = ({ title, message }) => {
  return (
    <div className="loading__modal">
      <div className="loading__content">
        <img src={loading} alt="loading" style={{ marginBottom: 15 }} />
        <div
          className="loading__title"
          style={{ fontSize: 24, fontWeight: 600 }}
        >
          {title}
        </div>
        <div className="loading__message">
          {message ? message : "Please wait.."}
        </div>
      </div>
    </div>
  );
};

export default LoadingDialog;
