import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../../api/axios/Axios";
import {
  Form,
  InputStack,
  Heading,
} from "../../../components/layout/layout/Layout";
import { Button } from "../../../components/button/Buttons";
import { TextField, CheckBox } from "../../../components/input/Inputs";
import GeneralPolicyPrivacy from "../../../assets/docs/PrivacyPolicy.pdf";
import MemberTermsAndConditions from "../../../assets/docs/MemberTermsAndConditions.pdf";
import { objectDataValidation } from "../../../hooks/Validation";
import { Main } from "../../../components/layout/container/Container";

const MemberSignUpPage = () => {
  const [firstName, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agreementChecked, setAgreementChecked] = useState(null);

  const navigateTo = useNavigate();

  const handleSubmit = () => {
    if (!validateEmail()) return;
    if (!handlePasswordInput()) return;

    const data = {
      firstName: firstName,
      email: email,
      password: password,
      agreement: agreementChecked,
    };

    if (!objectDataValidation(data)) {
      return alert("Please complete all required fields.");
    }

    return apiMemberSignup(data);
  };

  const validateEmail = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(email)) {
      alert("Email format is invalid.");
      return false;
    } else return true;
  };

  const apiMemberSignup = (data) => {
    Axios.post("/api/member/sign-up", data)
      .then((res) => {
        const { success, message } = res.data;
        if (!success) return alert(message);
        return (window.location.href = "/member/sign-in");
      })
      .catch((err) => console.error(err));
  };

  const handlePasswordInput = () => {
    if (password.length < 8 || password === "".trim()) {
      alert("Your password must be at least 8 characters long.");
      return false;
    } else return true;
  };

  const handleFirstNameInput = (e) => {
    if (e.target.value.length >= 45) return;
    return setFirstname(e.target.value);
  };

  return (
    <Main>
      <Form width={500}>
        <Heading title={"Sign Up as Member"} />

        <span style={{ opacity: 0.7, fontSize: 13 }}>
          I already have an account?{" "}
          <button
            type="button"
            onClick={() => navigateTo("/member/sign-in")}
            className="access-form__link"
          >
            Sign In
          </button>
        </span>

        <br />
        <br />

        <InputStack>
          <TextField
            label="First Name"
            value={firstName}
            onChange={(e) => handleFirstNameInput(e)}
          />

          <TextField
            type="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="e.g. email@domain.com"
          />

          <TextField
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Must have at least 8 characters"
            showPasswordOption
          />

          <div
            className="signup_terms__wrapper"
            style={{
              border:
                agreementChecked || agreementChecked === null
                  ? "" && agreementChecked === false
                  : "solid 2px red",
            }}
          >
            <CheckBox
              onChange={() => setAgreementChecked((check) => !check)}
              checked={agreementChecked}
              label={
                <span className="signup_terms__links">
                  I agree to the{" "}
                  <a
                    href={MemberTermsAndConditions}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    terms & conditions
                  </a>{" "}
                  and{" "}
                  <a
                    rel="noopener noreferrer"
                    href={GeneralPolicyPrivacy}
                    target="_blank"
                  >
                    privacy policy
                  </a>{" "}
                </span>
              }
            />
          </div>
        </InputStack>

        <br />

        <Button
          value="Sign Up"
          variant="fill"
          style={{ width: "100%" }}
          onClick={() =>
            agreementChecked === null
              ? setAgreementChecked(false)
              : handleSubmit()
          }
        />

        <br />
      </Form>
    </Main>
  );
};

export default MemberSignUpPage;
