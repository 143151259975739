import React, { useEffect, useState } from "react";
import { TextField } from "../../../../../components/input/Inputs";
import { Button } from "../../../../../components/button/Buttons";
import Axios from "../../../../../api/axios/Axios";
import {
  InputStack,
  Form,
} from "../../../../../components/layout/layout/Layout";
import Heading from "../../../../../components/heading/Heading";
import { ConfirmedDialog } from "../../../../../components/dialog/Dialog";
import { objectDataValidation } from "../../../../../hooks/Validation";

const PartnerBankingForm = () => {
  const [bsbNumber, setBsbNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [toggleAlert, setToggleAlert] = useState(false);

  useEffect(() => apiGetAccountDetails(), []);

  const apiGetAccountDetails = () => {
    Axios.get("/api/partner/select/banking-detail")
      .then((res) => {
        const data = res.data[0];

        setBsbNumber(data.bsbNumber ? data.bsbNumber : "");
        setAccountNumber(data.accountNumber ? data.accountNumber : "");
        return setAccountName(data.accountName ? data.accountName : "");
      })
      .catch((err) => console.error(err));
  };

  const handleSubmit = () => {
    const data = {
      bsbNumber: bsbNumber,
      accountNumber: accountNumber,
      accountName: accountName,
    };

    if (!objectDataValidation(data))
      return alert("Please complete all required fields.");

    return apiUpdateBankingDetails(data);
  };

  const apiUpdateBankingDetails = (data) => {
    Axios.post("/api/partner/update/banking-detail", data)
      .then((res) => {
        const { success } = res.data;
        if (success) return setToggleAlert(true);
      })
      .catch((err) => console.error(err));
  };

  const handleBsbNumberInput = (e) => setBsbNumber(e.target.value);
  const handleAccountNumberInput = (e) => setAccountNumber(e.target.value);
  const handleAccountNameInput = (e) => setAccountName(e.target.value);

  return (
    <Form>
      <Heading title={"Bank Account Details"} color={"black"} />

      <br />

      <span style={{ fontSize: 12 }}>
        All your earnings will be made to your nominated bank account
      </span>

      <br />
      <br />

      <InputStack>
        <TextField
          type="text"
          label="BSB Number"
          value={bsbNumber}
          onChange={handleBsbNumberInput}
        />

        <TextField
          type="text"
          label="Account Number"
          value={accountNumber}
          onChange={handleAccountNumberInput}
        />

        <TextField
          type="text"
          label="Account Name"
          value={accountName}
          onChange={handleAccountNameInput}
        />
      </InputStack>

      <br />

      <Button
        value="Update Bank Account"
        variant="fill"
        onClick={() => handleSubmit()}
        style={{ width: "100%" }}
      />

      {toggleAlert && (
        <ConfirmedDialog
          status="success"
          message="Updated"
          open={setToggleAlert}
        />
      )}

      <br />
    </Form>
  );
};

export default PartnerBankingForm;
