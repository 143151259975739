import React, { useState, useEffect } from "react";
import Axios from "../../../../../api/axios/Axios";
import { TextField, TextArea } from "../../../../../components/input/Inputs";
import { Button } from "../../../../../components/button/Buttons";
import {
  Form,
  InputStack,
} from "../../../../../components/layout/layout/Layout";
import Heading from "../../../../../components/heading/Heading";
import "./Form.css";
import { objectDataValidation } from "../../../../../hooks/Validation";
import { FormatDateForInput } from "../../../../../hooks/DateTime";

const PartnerEditCouponForm = ({ item, closeForm, refreshForm }) => {
  const [couponId, setCouponId] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponValue, setCouponValue] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [comment, setComment] = useState("");
  const [hasRedeemed, setHasRedeemed] = useState("");

  useEffect(() => setValues(), [item]);

  const setValues = () => {
    setCouponId(item.couponId);
    setCouponCode(item.couponCode);
    setCouponValue(item.couponValue);
    setExpiryDate(FormatDateForInput(item.expiryDate));
    setComment(item.comment);
    setHasRedeemed(item.hasRedeemed);
  };

  const handleSubmit = () => {
    const data = {
      couponId: couponId,
      couponValue: couponValue === "" ? "" : formatNumber(couponValue),
      expiryDate: expiryDate,
    };

    if (!objectDataValidation(data)) {
      return alert("Please complete all required fields.");
    }

    if (comment !== "") {
      data.comment = comment;
    } else data.comment = "";

    return apiUpdateCouponDetail(data);
  };

  const formatNumber = (number) => {
    if (number % 1 === 0) {
      return Math.round(number).toFixed(0);
    } else {
      return parseFloat(number).toFixed(2);
    }
  };

  const apiUpdateCouponDetail = (data) => {
    Axios.post("/update/partner/coupon", data)
      .then((res) => {
        const { success } = res.data;
        if (success) {
          refreshForm();
          return closeForm(false);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleCouponValue = (e) => {
    const value = e.target.value;
    if (!isNaN(value) || value === "") {
      return setCouponValue(value);
    }
  };

  const handleExpiryDateInput = (e) => {
    const value = e.target.value;
    if (value.length > 10) return;
    return setExpiryDate(value);
  };

  const handleCommentInput = (e) => {
    const value = e.target.value;
    if (value.length <= 300) {
      return setComment(value);
    }
  };

  const handleDeleteCoupon = () => {
    const msg = "Are you sure you want to delete this coupon?";
    const response = window.confirm(msg);
    if (response) {
      const data = { couponCode: couponCode, couponId: couponId };
      if (!objectDataValidation(data)) return false;

      return apiDeleteCoupon(data);
    } else return false;
  };

  const apiDeleteCoupon = (data) => {
    Axios.post("/delete/partner/coupon", data).then((res) => {
      const { success } = res.data;
      if (success) {
        refreshForm();
        return closeForm(false);
      }
    });
  };

  return (
    <Form width={700}>
      <Heading title={"Update Coupon Details"} color={"black"} />

      <br />
      <br />

      <InputStack>
        <TextField label="Coupon Code" value={couponCode} disabled />

        <TextField
          label={"Coupon Value (%)"}
          type="number"
          value={couponValue}
          placeholder={"e.g. 50 (number only)"}
          onChange={(e) => handleCouponValue(e)}
        />

        <TextField
          type="date"
          label="Expiry Date"
          value={expiryDate}
          onChange={(e) => handleExpiryDateInput(e)}
          placeholder="e.g. 01/07/2024"
        />

        <TextField disabled label={"Has Redeemed?"} value={hasRedeemed} />

        <TextArea
          label="Comment"
          placeholder="e.g. Player of the day"
          value={comment}
          onChange={(e) => handleCommentInput(e)}
        />
      </InputStack>

      <br />

      <Button
        value="Update Coupon Details"
        variant="fill"
        style={{ width: "100%" }}
        onClick={() => handleSubmit()}
      />

      <br />

      <Button
        value="Delete Coupon"
        variant="none"
        style={{ display: "flex", color: "red", marginLeft: "auto" }}
        onClick={() => handleDeleteCoupon()}
      />

      <br />
    </Form>
  );
};

export default PartnerEditCouponForm;
