import React from "react";
import "./Heading.css";

const Heading = ({ title, type, style, color }) => {
  return (
    <span className={`custom__heading ${type} ${color}`} style={style}>
      {title ? title : "Title"}
    </span>
  );
};

export default Heading;
