import React from "react";
import { FormatDateToLocaleDateString } from "../../../../../hooks/DateTime";
import "../../../../../components/styles/Table.css";
import NoDataDialog from "../../../../../components/dialog/NoDataDialog";

const Table = ({ data }) => {
  const handleToggleRentalExpiryStatus = (numOfDays) => {
    if (numOfDays === null) return "Watch Now";
    if (numOfDays > 0) return "Active";
    if (numOfDays === 0) return "Expires Soon";
    if (numOfDays < 0) return "Expired";
  };

  return (
    <>
      {data.length > 0 ? (
        <>
          <div className="table-container member-history__container">
            <table className="table">
              <thead>
                <tr>
                  <th className="table-th__title">Title</th>
                  <th>Amount</th>
                  <th>Payment Date</th>
                  <th>Expiry Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.rentId}>
                    <td className="table-td__title">{item.title}</td>
                    <td>{item.amount}</td>
                    <td>
                      {item.paymentDate
                        ? FormatDateToLocaleDateString(item.paymentDate)
                        : ""}
                    </td>
                    <td>
                      {item.expiryDate
                        ? FormatDateToLocaleDateString(item.expiryDate)
                        : ""}
                    </td>
                    <td>{handleToggleRentalExpiryStatus(item.expiresIn)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {data.map((item, index) => {
            return (
              <div key={index} className="member-history__wrapper">
                <span className="member-history__span">
                  <label>Title:</label>
                  <span>{item.title}</span>
                </span>

                <span className="member-history__span">
                  <label>Amount:</label>
                  <span>{item.amount}</span>
                </span>

                <span className="member-history__span">
                  <label>Payment Date:</label>
                  <span>{FormatDateToLocaleDateString(item.paymentDate)}</span>
                </span>

                <span className="member-history__span">
                  <label>Expiry Date:</label>
                  <span>{FormatDateToLocaleDateString(item.expiryDate)}</span>
                </span>

                <span className="member-history__span">
                  <label>Status:</label>
                  <span>{handleToggleRentalExpiryStatus(item.expiresIn)}</span>
                </span>
              </div>
            );
          })}
        </>
      ) : (
        <NoDataDialog
          title="Watchlist"
          message={"You have no purchased history"}
        />
      )}
    </>
  );
};

export default Table;
