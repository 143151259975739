import React, { useState, useEffect } from "react";
import Table from "../components/Table";
import Axios from "../../../../../api/axios/Axios";
import Heading from "../../../../../components/heading/Heading";

const EarningsList = () => {
  const [videoData, setVideoData] = useState([]);

  useEffect(() => apiGetEarningsList(), []);

  const apiGetEarningsList = () => {
    Axios.get("/select/partner/earnings/list", { params: { offSet: 0 } })
      .then((res) => {
        const data = res.data;
        return setVideoData(data);
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      <Heading title={"My Earnings"} />

      <br />

      <Table data={videoData} />
    </>
  );
};

export default EarningsList;
