import React from "react";
import { Outlet, Navigate } from "react-router-dom";

export function MemberPrivateRoute({ isLoggedIn, userType }) {
  if (isLoggedIn == false) return <Navigate to="/member/sign-in" />;
  if (isLoggedIn == true && userType === "Member") return <Outlet />;
}

export function AdminPrivateRoute({ isLoggedIn, userType }) {
  if (isLoggedIn == false) return <Navigate to="/admin/sign-in" />;
  if (isLoggedIn == true && userType === "Admin") return <Outlet />;
}

export function PartnerPrivateRoute({ isLoggedIn, userType }) {
  if (isLoggedIn == false) return <Navigate to="/partner/sign-in" />;
  if (isLoggedIn == true && userType === "Partner") return <Outlet />;
}
