import React, { useState, useEffect } from "react";
import Axios from "../../../../../api/axios/Axios";
import Table from "../components/Table";
import PartnerAddCouponForm from "../form/AddCouponForm";
import PartnerEditDiscountForm from "../form/EditCouponForm";
import Button from "../../../../../components/button/Button";
import Heading from "../../../../../components/heading/Heading";
import {
  Modal,
  ModalContent,
} from "../../../../../components/layout/modal/Modals";

const CouponList = () => {
  const [couponData, setCouponData] = useState([]);
  const [openAddCouponForm, setOpenAddCouponForm] = useState(false);
  const [openCouponEditForm, setOpenCouponEditForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => apiGetCouponsList(), []);

  const apiGetCouponsList = () => {
    Axios.get("/select/partner/coupons/list")
      .then((res) => {
        const data = res.data;
        return setCouponData(data);
      })
      .catch((err) => console.error(err));
  };

  const handleOpenEditForm = (id) => {
    const selectedItem = couponData.filter(
      (item) => item.couponId === id.couponId
    );
    setSelectedItem(selectedItem[0]);
    return setOpenCouponEditForm(true);
  };

  return (
    <>
      <span className="main-content_heading__span">
        <Heading title={"My Coupons"} />

        <Button
          className="main-content_heading__btn"
          variant="link"
          value="+ Add Coupon"
          onClick={() => setOpenAddCouponForm(true)}
        />
      </span>

      <br />

      <span
        style={{
          display: "flex",
          fontSize: 14,
          flexWrap: "wrap",
          color: "white",
        }}
      >
        <b style={{ color: "red", marginRight: 4, fontSize: 14 }}>
          Important:{" "}
        </b>{" "}
        The coupon is valid only for videos provided by the same partner.
      </span>

      <br />

      <Table data={couponData} couponId={handleOpenEditForm} />

      <Modal openModal={openAddCouponForm} closeModal={setOpenAddCouponForm}>
        <ModalContent>
          <PartnerAddCouponForm
            closeForm={setOpenAddCouponForm}
            refreshForm={apiGetCouponsList}
          />
        </ModalContent>
      </Modal>

      <Modal openModal={openCouponEditForm} closeModal={setOpenCouponEditForm}>
        <ModalContent>
          <PartnerEditDiscountForm
            item={selectedItem}
            closeForm={setOpenCouponEditForm}
            refreshForm={apiGetCouponsList}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default CouponList;
