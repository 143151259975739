import React, { useEffect } from "react";

const ConfirmedDialog = ({ message, open }) => {
  useEffect(() => handleAlertToggle(), [open]);

  const handleAlertToggle = () => {
    if (open) {
      setInterval(() => {
        open(false);
      }, 1500);
    }
  };

  return (
    <>
      {open && (
        <div className="alert__container">
          <div className="alert__content">{message}</div>
        </div>
      )}
    </>
  );
};

export default ConfirmedDialog;
