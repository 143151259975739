import React from "react";
import "./Button.css";
import spinner from "../../assets/gif/loading.gif";

const Button = (props) => {
  const { disabled, className, variant, onClick, style, value, showSpinner } =
    props;
  return (
    <button
      disabled={disabled ? true : false}
      type="button"
      className={`custom-button  ${className} ${variant ? variant : "fill"}`}
      onClick={onClick}
      style={style}
    >
      {showSpinner && (
        <img
          src={spinner}
          alt="spinner"
          style={{ width: 24, marginRight: 8 }}
        />
      )}{" "}
      {value ? value : "Value"}
    </button>
  );
};

export default Button;
