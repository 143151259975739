import React from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import spinner from "../../assets/gif/loading.gif";
import "./SportCard.css";
import { PiPlayFill } from "react-icons/pi";

const SportCard = ({
  title,
  duration,
  videoClicked,
  dropmenu,
  showDropmenu,
  menuIconOnClicked,
  menuIcon,
  thumbnail,
  channelName,
  cardContainerStyle,
  expiresIn,
  channelClicked,
  mainContainer,
  createdAt,
  playIcon,
  newLabelNotification,
  avatar,
}) => {
  const elSpinner = (
    <div className="sport-card__spinner">
      <img src={spinner} alt="spinner" />
    </div>
  );

  const Thumbnail = () => {
    const handleMenuOptionsOnClicked = (e) => {
      e.stopPropagation();
      return menuIconOnClicked();
    };

    const EditOption = () => {
      return (
        <div className="custom-sport-card_edit__con">
          <button className="custom-sport-card_edit__btn">
            <BiDotsVerticalRounded
              className="custom-sport-card_edit__icon"
              onClick={handleMenuOptionsOnClicked}
            />
          </button>

          {showDropmenu && (
            <div className="custom-sport-card_dropmenu__con">{dropmenu}</div>
          )}
        </div>
      );
    };

    const PlayIcon = () => {
      return (
        <div className="custom-sport-card_play-icon__wrapper">
          <PiPlayFill className="custom-sport-card__play-icon" />
        </div>
      );
    };

    const ExpiryLabel = () => {
      return (
        <span
          className="custom-sport-card__expireIn"
          style={{
            background: expiresIn === "Expired" && "rgba(220, 20, 60, 0.8)",
            color: expiresIn === "Expired" && "white",
          }}
        >
          {expiresIn}
        </span>
      );
    };

    return (
      <div className="custom-sport-card_thumb__wrapper">
        <img src={thumbnail} alt="thumb" />
        <p className="custom-sport-card__duration">{duration}</p>

        {menuIcon && <EditOption />}
        {playIcon && <PlayIcon />}
        {newLabelNotification && <NewLabelNotification />}
        {expiresIn && <ExpiryLabel />}
      </div>
    );
  };

  const NewLabelNotification = () => {
    return <div className="custom-sport-card__new-label-notification">New</div>;
  };

  const Description = () => {
    const handleChannelClicked = (e) => {
      e.stopPropagation();
      return channelClicked();
    };

    const truncate = (str, num) => {
      return str.length > num ? str.slice(0, num) + "..." : str;
    };

    const Avatar = () => {
      return (
        <div className="custom-sport-card__avatar">
          {avatar && avatar.charAt(0)}
        </div>
      );
    };

    return (
      <div className="custom-sport-card_description__con">
        <div className="custom-sport-card_description__content">
          <p className="custom-sport-card__title">{truncate(title, 60)}</p>

          <div className="custom-sport-card_channel-name-n-date__con">
            {channelName ? (
              <>
                <div
                  className="custom-sport-card__channel-name"
                  onClick={handleChannelClicked}
                >
                  {channelName}
                </div>

                <div className="custom-sport-card__created-at">{createdAt}</div>
              </>
            ) : (
              <span className="custom-sport-card__created-at">{createdAt}</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleVideoClicked = (e) => {
    e.stopPropagation();
    return videoClicked();
  };

  return (
    <div
      style={cardContainerStyle}
      className={`custom-sport-card__container ${mainContainer} ${
        channelName && "custom-sport-card_remove-border-radius__bottom"
      }`}
      onClick={handleVideoClicked}
    >
      {thumbnail ? (
        <>
          <Thumbnail />
          <Description />
        </>
      ) : (
        thumbnail && elSpinner
      )}
    </div>
  );
};

export default SportCard;
