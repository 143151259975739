import React from "react";
import "./TextLabel.css";

const TextLabel = ({ label, value, className, row, column }) => {
  return (
    <div className={`c_textLabel__con ${className} ${row ? "row" : "column"}`}>
      <label className="c_textLabel__lbl">{label ? label : "Label"}</label>
      <p className="c_textLabel__p">{value ? value : "Text"}</p>
    </div>
  );
};

export default TextLabel;
