import React from "react";

const MainContainer = ({ children, backgroundColor }) => {
  return (
    <div className={`custom__main-container ${backgroundColor}`}>
      {children}
    </div>
  );
};

export default MainContainer;
