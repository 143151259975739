import React from "react";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../../../components/layout/container/Container";
import CouponList from "./view/CouponList";

const PartnerCouponsPage = () => {
  return (
    <Main>
      <Content>
        <ContentWrapper>
          <CouponList />
        </ContentWrapper>
      </Content>
    </Main>
  );
};

export default PartnerCouponsPage;
