import React, { useEffect, useState } from "react";
import MatchList from "./view/MatchList";
import Footer from "../../components/layout/footer/Footer";
import "./Home.css";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../components/layout/container/Container";
import BannerImage from "../../assets/images/web-ad-banner.png";
import Filters from "./components/Filters";

const Home = ({ isLoggedIn, userType }) => {
  const [showFooter, setShowFooter] = useState(false);
  const [filteredValue, setFilteredValue] = useState("");

  useEffect(() => {
    setTimeout(() => setShowFooter(true), 3000);
  }, []);

  const AdBanner = () => {
    return (
      <div className="home_ad-banner__con">
        <img src={BannerImage} alt="banner" />

        <h1 className="home_ad-banner__title">
          Sports Video Sharing Platform
          <br />
          <span className="home_ad-banner__slogan">
            You can earn money JUST by uploading videos
          </span>
        </h1>
      </div>
    );
  };

  const getSelectedFilterValue = (value) => setFilteredValue(value);

  return (
    <Main>
      <Content scroll={"scroll-Y"}>
        <ContentWrapper width={1200} position={"center"}>
          <Filters selectedFilter={getSelectedFilterValue} />

          <br />

          <AdBanner />

          <br />

          <MatchList
            isLoggedIn={isLoggedIn}
            userType={userType}
            filteredValue={filteredValue}
          />
          <br />
          <br />
          <br />
        </ContentWrapper>

        {showFooter && <Footer />}
      </Content>
    </Main>
  );
};

export default Home;
