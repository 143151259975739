import React from "react";
import { useNavigate } from "react-router-dom";
import { FormatDateToLocaleDateString } from "../../hooks/DateTime";
import HorizontalVideoPlayer from "../../components/player/horizontal/HorizontalVideoPlayer";
import { Main } from "../../components/layout/container/Container";
import "./Watch.css";
import Filters from "./components/Filters";

const FullMatchReplay = ({ data, isLoggedIn }) => {
  const navigateTo = useNavigate();

  const handleChannelClicked = () => {
    return navigateTo(`/${data.userName}`);
  };

  return (
    <Main>
      <div className="watch-now__container">
        <div className="watch-now__content">
          <div className="watch-now__player-wrapper">
            <HorizontalVideoPlayer
              videoUrl={data.videoUrl}
              showControls={true}
            />
          </div>

          <div className="watch-now__detail-con">
            <span className="watch-now__title">{data.title}</span>

            <div className="watch-now__channel-info">
              <span
                className="watch-now__channel-name"
                onClick={handleChannelClicked}
              >
                {data.channelName}
              </span>

              <span className="watch-now__video-count">
                {data.totalVideos <= 1
                  ? data.totalVideos + " video"
                  : data.totalVideos + " videos"}
              </span>
            </div>

            <Filters
              videoId={data.videoId}
              partnerId={data.partnerId}
              isLoggedIn={isLoggedIn}
            />

            <div className="watch-now__video-description">
              <span className="watch-now__match-date">
                {FormatDateToLocaleDateString(data.createdAt)}
              </span>

              <p className="watch-now__description">{data.description}</p>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />
      </div>
    </Main>
  );
};

export default FullMatchReplay;
