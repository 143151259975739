import React from "react";
import img404 from "../../assets/images/error-404.png";
import "./Error.css";

const Error404Page = () => {
  return (
    <main className="error-page__container">
      <div className="error-page__img-wrapper">
        <img src={img404} alt="error" />
      </div>
    </main>
  );
};

export default Error404Page;
