import React, { useEffect, useState } from "react";
import { LoadingDialog } from "../../../../components/dialog/Dialog";
import { currentDate } from "../../../../hooks/DateTime";
import { objectDataValidation } from "../../../../hooks/Validation";
import Axios from "../../../../api/axios/Axios";
import { InputStack } from "../../../../components/layout/layout/Layout";
import Heading from "../../../../components/heading/Heading";
import {
  TextField,
  Select,
  TextArea,
} from "../../../../components/input/Inputs";
import { Button } from "../../../../components/button/Buttons";
import SelectVideoAndImage from "../../../../components/input/file/SelectVideoAndImage";
import { v4 as uuidv4 } from "uuid";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../../../components/layout/container/Container";
import "./Styles.css";

// ⭐ S3 Upload::::::
import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";

const PartnerAddVideoPage = () => {
  const [videoFile, setVideoFile] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [videoDuration, setVideoDuration] = useState("");
  const [title, setTitle] = useState("");
  const [sport, setSport] = useState("Rugby Union");
  const [tournament, setTournament] = useState("XVs");
  const [competitionLevel, setCompetitionLevel] = useState("Juniors");
  const [matchDate, setMatchDate] = useState(currentDate());
  const [description, setDescription] = useState("");
  const [replay, setReplay] = useState("Full Game");
  const [assignedVideoId, setAssignedVideoId] = useState(null);

  const [twoDayPrice, setTwoDayPrice] = useState("2.80");
  const [fiveDayPrice, setFiveDayPrice] = useState("5.60");

  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => generateRandomVideoId(), []);

  const generateRandomVideoId = () => {
    const newRandomId = uuidv4();
    const newVideoId = newRandomId
      .substring(0, 13)
      .replace(/[^a-zA-Z0-9]/g, "");
    return setAssignedVideoId(newVideoId);
  };

  const getThumbnailFileObject = (file) => setThumbnailFile(file);
  const getVideoFileObject = (file) => setVideoFile(file);
  const getVideoDuration = (time) => setVideoDuration(time);

  const handleSubmit = () => {
    if (!videoFile) return;
    if (!fileSizeChecker(videoFile.size)) return;
    if (!validateMinimumPriceAllow()) return;

    const data = {
      assignedVideoId: assignedVideoId,
      title: title,
      sportType: sport,
      tournamentType: tournament,
      competitionLevel: competitionLevel,
      matchDate: matchDate,
      description: description,
      twoDayPrice: parseFloat(twoDayPrice).toFixed(2),
      fiveDayPrice: parseFloat(fiveDayPrice).toFixed(2),
      replayType: replay,
      videoDuration: videoDuration,
      videoFileSize: videoFile.size,
    };

    if (!objectDataValidation(data) || !videoFile || !thumbnailFile) {
      return alert("Please complete all required fields.");
    }

    return apiGetS3Credentials(data);
  };

  const handleTwoDayPriceInput = (e) => {
    const value = e.target.value;
    if (value.length <= 5) return setTwoDayPrice(value);
  };

  const handleSevenDayPriceInput = (e) => {
    const value = e.target.value;
    if (value.length <= 5) return setFiveDayPrice(value);
  };

  const handleTitleInput = (e) => {
    if (e.target.value.length <= 100) return setTitle(e.target.value);
  };

  const handleDurationInput = (e) => {
    const value = e.target.value;
    return setVideoDuration(value);
  };

  const handleDescriptionInput = (e) => {
    const value = e.target.value;
    if (value.length <= 300) return setDescription(value);
  };

  const handleSelectSport = (value) => setSport(value);
  const handleSelectTournament = (value) => setTournament(value);
  const handleSelectCompetitionLevel = (value) => {
    setCompetitionLevel(value);
    return toggleMininumPrice(value);
  };
  const handleSelectReplayType = (value) => setReplay(value);

  const validateMinimumPriceAllow = () => {
    const msg =
      "The set price must be equal to or greater than the minimum amount.";

    const alertPrompt = () => {
      alert(msg);
      return false;
    };

    const minimumPrices = {
      Juniors: { twoDay: 2.8, fiveDay: 5.6 },
      Seniors: { twoDay: 3.8, fiveDay: 7.6 },
      Mixed: { twoDay: 3.8, fiveDay: 7.6 },
    };

    const isValidPrice = (competitionLevel, priceType, price) =>
      parseFloat(price) >= minimumPrices[competitionLevel][priceType];

    if (!twoDayPrice || !fiveDayPrice) return alertPrompt();

    if (
      !isValidPrice(competitionLevel, "twoDay", twoDayPrice) ||
      !isValidPrice(competitionLevel, "fiveDay", fiveDayPrice)
    ) {
      return alertPrompt();
    }

    return true;
  };

  const fileSizeChecker = (size) => {
    const inputFileSize = size;
    const maxFileSizeInGB = 7;
    const bytes = 1024;
    const maxFileSizeAllow = Number(maxFileSizeInGB) * Number(bytes) ** 3;

    if (inputFileSize > maxFileSizeAllow) {
      const msg = "Selected file size should not exceed 7GB.";
      alert(msg);
      return false;
    } else return true;
  };

  const toggleMininumPrice = (value) => {
    const prices = {
      Juniors: { twoDay: "2.80", fiveDay: "5.60" },
      Seniors: { twoDay: "3.80", fiveDay: "7.60" },
      Mixed: { twoDay: "3.80", fiveDay: "7.60" },
    };

    if (prices[value]) {
      setTwoDayPrice(prices[value].twoDay);
      return setFiveDayPrice(prices[value].fiveDay);
    }
  };

  // --------------------------
  // 📝 Upload Selected Files:
  // --------------------------

  const apiGetS3Credentials = (data) => {
    Axios.get("/read/s3/credentials")
      .then((res) => {
        const credentials = res.data;
        setIsLoading(true);
        return s3UploadMatchVideo(credentials, data);
      })
      .catch((err) => console.error(err));
  };

  const s3UploadMatchVideo = async (s3Data, data) => {
    const {
      bucketName,
      region,
      accessKeyId,
      secretAccessKey,
      videoFileName,
      videoFolderDir,
      thumbnailFileName,
      thumbnailFolderDir,
    } = s3Data;

    const fileExtension = videoFile.type.split("/")[1];
    const outputVideoFileName = `${videoFileName}.${fileExtension}`;

    const S3 = new S3Client({
      region: region,
      credentials: {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
      },
    });

    const params = {
      Bucket: bucketName,
      Key: videoFolderDir + outputVideoFileName,
      Body: videoFile,
      ContentType: videoFile.type,
    };

    const uploadToS3 = new Upload({ client: S3, params });

    uploadToS3.on("httpUploadProgress", (progressData) => {
      const percentageProgress = Math.round(
        (progressData.loaded / progressData.total) * 99
      );
      return setProgress(percentageProgress);
    });

    const response = await uploadToS3.done();

    if (response) {
      data.videoFileName = outputVideoFileName;

      return s3UploadMatchThumbnail(
        S3,
        bucketName,
        thumbnailFileName,
        thumbnailFolderDir,
        data
      );
    }
  };

  const s3UploadMatchThumbnail = async (
    S3,
    bucketName,
    fileName,
    folderDir,
    data
  ) => {
    const fileExtension = thumbnailFile.type.split("/")[1];
    const outputThumbnailFileName = `${fileName}.${fileExtension}`;

    const params = {
      Bucket: bucketName,
      Key: folderDir + outputThumbnailFileName,
      Body: thumbnailFile,
      ContentType: thumbnailFile.type,
    };

    const uploadToS3 = new Upload({ client: S3, params });
    const response = await uploadToS3.done();

    if (response) {
      data.thumbnailFileName = outputThumbnailFileName;
      return saveMatchDetailToDatabase(data);
    }
  };

  const saveMatchDetailToDatabase = (data) => {
    Axios.post("/add/partner/match", data)
      .then((res) => {
        const { success } = res.data;
        if (success) return (window.location.href = "/partner/matches");
      })
      .catch((err) => console.error(err));
  };

  // --------------------
  // ⌚ Upload Progress:
  // --------------------

  const handleProgressMessage = () => {
    let processStatus = "Preparing";

    if (progress === 0) return processStatus;
    if (progress > 0 && progress < 90) return (processStatus = "Processing");
    if (progress > 90 && progress <= 99) return (processStatus = "Finalising");

    return processStatus;
  };

  // --------------------
  // ⬇️ Closing browser:
  // --------------------

  useEffect(() => {
    if (progress >= 1 && progress <= 98) return handleClosingBrowserTab();
  }, [progress]);

  const handleClosingBrowserTab = () => {
    const handleBeforeUnload = (e) => {
      const message =
        "Are you sure you want to leave? Your changes may not be saved.";

      e.preventDefault();
      e.returnValue = message;

      return message;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  };

  return (
    <Main backgroundColor={"white"}>
      <Content scroll={"scroll-Y"}>
        <ContentWrapper maxWidth={"largeWidth"}>
          <Heading title={"Upload Video"} color={"black"} />

          <br />

          <p className="main-content_heading__prompt">
            <strong style={{ color: "red" }}>Important: </strong>
            It is recommended to keep your video resolution at 1920x1080 or
            lower, and the file size should not exceed 7GB. Please ensure that
            your file meets these requirements before uploading.
          </p>

          <br />

          <SelectVideoAndImage
            getVideoFileObject={getVideoFileObject}
            getVideoDuration={getVideoDuration}
            getThumbnailFileObject={getThumbnailFileObject}
          />

          <br />

          <InputStack>
            <TextField
              label="Title"
              value={title}
              onChange={handleTitleInput}
              placeholder="Enter title of your video"
            />

            <InputStack direction={"row"}>
              <Select
                label="Sport"
                value={sport}
                items={[{ value: "Rugby Union" }, { value: "Rugby League" }]}
                onChange={handleSelectSport}
              />

              <TextField
                label="Match Date"
                type="date"
                value={matchDate}
                onChange={(e) => setMatchDate(e.target.value)}
              />
            </InputStack>

            <InputStack direction={"row"}>
              <Select
                label="Tournament"
                value={tournament}
                items={[
                  { value: "XVs" },
                  { value: "10s" },
                  { value: "7s" },
                  { value: "Other" },
                ]}
                onChange={handleSelectTournament}
              />

              <Select
                label="Competition"
                value={competitionLevel}
                items={[
                  { value: "Seniors" },
                  { value: "Juniors" },
                  { value: "Mixed" },
                ]}
                onChange={handleSelectCompetitionLevel}
              />
            </InputStack>

            <InputStack direction={"row"}>
              <Select
                label="Replay"
                value={replay}
                items={[
                  { value: "Full Game" },
                  { value: "Part Game" },
                  { value: "Highlights" },
                  { value: "Mixed Clips" },
                ]}
                onChange={handleSelectReplayType}
              />

              <TextField
                label="Video Duration (mins)"
                type="text"
                value={!videoDuration ? "" : videoDuration}
                placeholder="0"
                onChange={handleDurationInput}
                disabled
              />
            </InputStack>

            <InputStack direction={"row"}>
              <TextField
                label="Price One (2 day period)"
                type="num"
                value={twoDayPrice}
                placeholder={`A min. amount of ${
                  competitionLevel === "Juniors" ? "2.90" : "3.90"
                }`}
                onChange={(e) => handleTwoDayPriceInput(e)}
              />

              <TextField
                label="Price Two (5 day period)"
                type="num"
                value={fiveDayPrice}
                placeholder={`A min. amount of ${
                  competitionLevel === "Juniors" ? "5.80" : "7.80"
                }`}
                onChange={(e) => handleSevenDayPriceInput(e)}
              />
            </InputStack>

            <TextArea
              label="Description"
              placeholder="Tell us more about this video.."
              value={description}
              required
              onChange={(e) => handleDescriptionInput(e)}
            />
          </InputStack>

          <br />

          <Button
            type="button"
            variant="fill"
            style={{ width: "100%" }}
            onClick={(e) => handleSubmit(e)}
            value="Upload Video"
          />

          {isLoading && (
            <LoadingDialog
              title="Uploading"
              message={`${handleProgressMessage()} ${progress}%`}
            />
          )}

          <br />
          <br />
          <br />
          <br />
        </ContentWrapper>
      </Content>
    </Main>
  );
};

export default PartnerAddVideoPage;
