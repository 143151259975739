import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../../api/axios/Axios";
import { TextField } from "../../../components/input/Inputs";
import { Button } from "../../../components/button/Buttons";
import { Form, InputStack } from "../../../components/layout/layout/Layout";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../../components/layout/container/Container";
import Heading from "../../../components/heading/Heading";
import { objectDataValidation } from "../../../hooks/Validation";

function AdminSignUp() {
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [passkey, setPassKey] = useState("");

  const navigateTo = useNavigate();

  const handleSubmit = () => {
    if (!handleEmailValidation()) return;
    if (!handlePasswordValidation()) return;

    const data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      dateOfBirth: dateOfBirth,
      passKey: passkey,
    };

    if (!objectDataValidation(data)) {
      return alert("Please complete all required fields.");
    }

    return apiAddNewAdmin(data);
  };

  const apiAddNewAdmin = (data) => {
    Axios.post("/add/admin/sign-up", data)
      .then((res) => {
        const { success, message } = res.data;
        const msg = `${message} Please contact our Tech Support.`;
        if (!success) return alert(msg);

        return navigateTo("/admin/login");
      })
      .catch((err) => console.error(err));
  };

  const handleEmailValidation = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(email)) {
      alert("Email format is invalid.");
      return false;
    } else return true;
  };

  const handlePasswordValidation = () => {
    if (password.length < 8 || password === "".trim()) {
      alert("Your password must be at least 8 characters long.");
      return false;
    } else return true;
  };

  const handleFirstNameInput = (e) => {
    if (e.target.value.length >= 45) return;
    return setFirstname(e.target.value);
  };

  const handleLastNameInput = (e) => {
    if (e.target.value.length >= 45) return;
    return setLastname(e.target.value);
  };

  const handleDateOfBirthInput = (e) => {
    if (e.target.value.length > 10) return;
    return setDateOfBirth(e.target.value);
  };

  return (
    <Main>
      <Content scroll={"scroll-Y"}>
        <ContentWrapper width={500}>
          <Form>
            <Heading title={"Sign Up as Admin"} color={"black"} />

            <br />
            <br />

            <InputStack>
              <TextField
                label="First Name"
                value={firstName}
                onChange={(e) => handleFirstNameInput(e)}
              />

              <TextField
                label="Last Name"
                value={lastName}
                onChange={(e) => handleLastNameInput(e)}
              />

              <TextField
                type="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="e.g. email@domain.com"
              />

              <TextField
                type="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Must have at least 8 characters"
                showPasswordOption
              />

              <TextField
                type="date"
                label="Date Of Birth"
                value={dateOfBirth}
                onChange={(e) => handleDateOfBirthInput(e)}
                placeholder="e.g. 01/07/1980"
              />

              <TextField
                label="Pass Key"
                onChange={(e) => setPassKey(e.target.value)}
              />
            </InputStack>

            <br />

            <Button
              value="Sign Up"
              variant="fill"
              onClick={() => handleSubmit()}
              style={{ width: "100%" }}
            />

            <br />
          </Form>
        </ContentWrapper>
      </Content>
    </Main>
  );
}

export default AdminSignUp;
