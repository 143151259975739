import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../../../../api/axios/Axios";
import Filters from "../components/Filters";
import "../Matches.css";
import "../../../page/Styles.css";
import Table from "../components/Table";
import Heading from "../../../../../components/heading/Heading";

const PartnerUploadsList = () => {
  const [videoData, setVideoData] = useState([]);

  const [visibility, setVisibility] = useState("Published");
  const [publishedVideoCount, setPublishedVideoCount] = useState(0);
  const [inReviewCount, setInReviewCount] = useState(0);
  const [declinedVideoCount, setDeclinedVideoCount] = useState(0);

  const navigateTo = useNavigate();

  useEffect(() => apiGetMatchesVideoCount(), []);
  useEffect(() => apiGetMatchesList(), [visibility]);

  const apiGetMatchesVideoCount = () => {
    Axios.get("/select/partner/matches/count")
      .then((res) => {
        const data = res.data[0];
        setPublishedVideoCount(data.publishedCount);
        setInReviewCount(data.inReviewCount);
        setDeclinedVideoCount(data.declinedCount);
        return;
      })
      .catch((err) => console.error(err));
  };

  const apiGetMatchesList = () => {
    Axios.get("/select/partner/match/list", {
      params: { visibility: visibility, offSet: 0 },
    })
      .then((res) => {
        const data = res.data;
        return setVideoData(data);
      })
      .catch((err) => console.error(err));
  };

  const handleEditMatch = (obj) => {
    return navigateTo(`/partner/edit/video/${obj.videoId}`);
  };

  const toggleVisibility = (status) => {
    return setVisibility(status);
  };

  const handleSelectedMatchOnClick = (obj) => {
    return navigateTo(`/watch/${obj.videoId}`);
  };

  return (
    <>
      <Heading title={"My Uploads"} />

      <br />

      <Filters
        visibility={visibility}
        publishedOnClick={() => toggleVisibility("Published")}
        inReviewOnClick={() => toggleVisibility("In Review")}
        declinedOnClick={() => toggleVisibility("Declined")}
        publishedVideoCount={publishedVideoCount}
        inReviewVideoCount={inReviewCount}
        declinedVideoCount={declinedVideoCount}
      />

      <br />

      <Table
        data={videoData}
        titleOnClick={handleSelectedMatchOnClick}
        iconOnClick={handleEditMatch}
      />
    </>
  );
};

export default PartnerUploadsList;
