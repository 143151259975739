import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../../api/axios/Axios";
import { Form, InputStack } from "../../../components/layout/layout/Layout";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../../components/layout/container/Container";
import Heading from "../../../components/heading/Heading";
import { objectDataValidation } from "../../../hooks/Validation";
import { Button } from "../../../components/button/Buttons";
import { TextField } from "../../../components/input/Inputs";

const AdminSignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const navigateTo = useNavigate();

  const apiAdminSignIn = (data) => {
    Axios.post("/api/admin/login", data)
      .then((res) => {
        const { success, message } = res.data;
        if (!success) return setMessage(message);
        return (window.location.href = "/admin/matches");
      })
      .catch((err) => console.error(err));
  };

  const handleSubmit = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(email)) {
      return alert("Invalid email format.");
    } else {
      const data = {
        email: email,
        password: password,
      };

      if (!objectDataValidation(data)) {
        return alert("Please complete all required fields.");
      }

      return apiAdminSignIn(data);
    }
  };

  const HandleKeyDown = (e) => e.keyCode === 13 && handleSubmit(e);

  return (
    <Main>
      <Content>
        <ContentWrapper width={500}>
          <Form>
            <Heading title={"Sign In as Admin"} style={{ color: "black" }} />

            <br />
            <br />

            {message && (
              <div
                style={{
                  color: "white",
                  padding: 16,
                  background: "#ff4040",
                  textAlign: "center",
                  marginBottom: 16,
                  borderRadius: 8,
                }}
              >
                {message}
              </div>
            )}

            <InputStack>
              <TextField
                type="email"
                label="Email"
                value={email}
                placeholder="e.g. admin@domain.com"
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => HandleKeyDown(e)}
              />

              <TextField
                type="password"
                label="Password"
                value={password}
                placeholder="********"
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => HandleKeyDown(e)}
                showPasswordOption
              />

              <Button
                value="Forgot Password"
                variant="none"
                onClick={() => navigateTo("/forgot-password?user=admin")}
                style={{
                  display: "flex",
                  margin: "-10px 0 0 auto",
                  fontSize: 14,
                  color: "#0d0d0d",
                }}
              />
            </InputStack>

            <br />

            <Button
              value="Login"
              variant="fill"
              style={{ width: "100%" }}
              onClick={(e) => handleSubmit(e)}
            />

            <br />
          </Form>
        </ContentWrapper>
      </Content>
    </Main>
  );
};

export default AdminSignIn;
