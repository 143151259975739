import React from "react";
import "./Layout.css";

export const Form = ({
  children,
  style,
  width,
  height,
  position,
  classname,
  color,
  scrollY,
  onSubmit,
  top,
}) => {
  const setPosition = () => {
    if (position === "Left") return "auto 0 0 0";
    if (position === "Center") return "0 auto 0 auto";
    if (position === "Right") return "0 0 0 auto";
  };

  return (
    <form
      onSubmit={(e) => onSubmit(e)}
      className={`__form ${classname}`}
      style={{
        display: "block",
        width: !width ? "100%" : width,
        height: !height ? "fit-Content" : height,
        margin: !position ? "0 auto 0 auto" : setPosition(),
        marginTop: top ? top : 0,
        padding: 16,
        background: !color ? "white" : color,
        ...style,
      }}
    >
      {children}
    </form>
  );
};

export const InputStack = ({
  children,
  direction,
  margin,
  style,
  classname,
  top,
  bottom,
}) => {
  return (
    <div
      className={`__inputStack ${classname}`}
      style={{
        display: "flex",
        flexDirection: !direction ? "column" : direction,
        gap: !margin ? 16 : margin,
        marginTop: !top ? 0 : top,
        marginBottom: !bottom ? 0 : bottom,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export const ListGrid = ({ children, style, className }) => {
  return (
    <div className={`__listGrid ${className}`} style={{ ...style }}>
      {children}
    </div>
  );
};

export const GroupStack = ({
  children,
  direction,
  margin,
  style,
  classname,
}) => {
  return (
    <div
      className={`__groupStack ${classname}`}
      style={{
        display: "flex",
        flexDirection: !direction ? "column" : direction,
        gap: !margin ? 16 : margin,
        width: "100%",
        background: "transparent",
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export const Heading = ({ title, size, color, top, bottom, weight }) => {
  return (
    <div
      style={{
        fontSize: !size ? "1.5rem" : size,
        color: !color ? "#1a1a1a" : color,
        marginTop: !top ? 0 : top,
        marginBottom: !bottom ? 0 : bottom,
        fontWeight: !weight ? 600 : weight,
      }}
    >
      {title}
    </div>
  );
};
