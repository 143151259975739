import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../components/button/Buttons";
import Axios from "../../../api/axios/Axios";
import { Form, Heading } from "../../../components/layout/layout/Layout";
import { objectDataValidation } from "../../../hooks/Validation";
import { TextField } from "../../../components/input/Inputs";
import { PiXCircleBold } from "react-icons/pi";
import { Main } from "../../../components/layout/container/Container";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [showInput, setShowInput] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useParams();

  const navigateTo = useNavigate();

  const handleSubmit = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(email)) {
      return alert("Invalid email format.");
    } else {
      const data = {
        userType: user.charAt(0).toUpperCase() + user.slice(1).toLowerCase(),
        email: email,
      };

      if (!objectDataValidation(data)) return alert("Please enter your email.");

      return apiForgotPassword(data);
    }
  };

  const apiForgotPassword = (data) => {
    Axios.post("/api/forgot-password", data, {
      onUploadProgress: () => setIsLoading(true),
    })
      .then((res) => {
        const { success } = res.data;

        if (!success) {
          alert("Email not found!");
          return setIsLoading(false);
        } else {
          setShowInput(false);
          return setIsLoading(false);
        }
      })
      .catch((err) => console.error(err));
  };

  const HandleKeyDown = (e) => e.keyCode === 13 && handleSubmit();

  const ForgotPasswordConfirmation = () => {
    return (
      <div className="reset-password-form_expired__con">
        <PiXCircleBold
          className="reset-password-form_expired__icon"
          style={{ color: "green" }}
        />

        <span className="reset-password-form_expired__title">
          Forgot Password Confirmation
        </span>

        <p className="reset-password-form__message">
          You should receive an email soon with instructions to reset your
          password. Please also check your junk or spam folder.
        </p>
      </div>
    );
  };

  const handleFormNavigation = () => {
    if (user === "member") return navigateTo("/member/sign-in");
    if (user === "partner") return navigateTo("/partner/sign-in");
    if (user === "admin") return navigateTo("/admin/sign-in");
  };

  return (
    <Main>
      <Form width={500}>
        {showInput ? (
          <>
            <Heading title={"Forgot Password"} />

            <span style={{ opacity: 0.7, fontSize: 13 }}>
              Did you remember your password?{" "}
              <button
                type="button"
                onClick={() => handleFormNavigation()}
                className="access-form__link"
              >
                Sign In
              </button>
            </span>

            <br />
            <br />

            <TextField
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => HandleKeyDown(e)}
              placeholder="e.g. email@domain.com"
            />

            <br />

            <Button
              disabled={isLoading}
              value="Forgot Password"
              style={{ width: "100%" }}
              onClick={() => handleSubmit()}
              showSpinner={isLoading}
            />
          </>
        ) : (
          <ForgotPasswordConfirmation />
        )}

        <br />
      </Form>
    </Main>
  );
};

export default ForgotPassword;
