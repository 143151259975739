import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../../api/axios/Axios";
import {
  TextField,
  CheckBox,
  TextArea,
} from "../../../components/input/Inputs";
import { Button } from "../../../components/button/Buttons";

import { Form, InputStack } from "../../../components/layout/layout/Layout";
import Heading from "../../../components/heading/Heading";
import "../Access.css";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../../components/layout/container/Container";
import { v4 as uuidv4 } from "uuid";

import TermsOfUse from "../../../assets/docs/MemberTermsAndConditions.pdf";
import GeneralPolicyPrivacy from "../../../assets/docs/PrivacyPolicy.pdf";
import PartnerTermsAndConditions from "../../../assets/docs/PartnershipProgramTermsAndConditions.pdf";
import { objectDataValidation } from "../../../hooks/Validation";
const MAX_CHAR_LIMIT = 500;

const PartnerSignUpPage = () => {
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [channelName, setChannelName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [agreementChecked, setAgreementChecked] = useState(null);
  const [about, setAbout] = useState("");
  const [userName, setUserName] = useState("");

  const [charCount, setCharCount] = useState(MAX_CHAR_LIMIT);
  const [errorMessage, setErrorMessage] = useState("");

  const navigateTo = useNavigate();

  useEffect(() => generateRandomKey(), []);

  const generateRandomKey = () => {
    const newRandomKey = uuidv4();
    return setUserName(newRandomKey.substring(0, 12).toUpperCase());
  };

  const handleSubmit = () => {
    if (!handleEmailValidation()) return;
    if (!handlePasswordValidation()) return;

    const data = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      password: password.trim(),
      dateOfBirth: dateOfBirth.trim(),
      channelName: channelName.trim(),
      userName: userName.trim(),
      about: about,
      agreement: agreementChecked,
    };

    if (!objectDataValidation(data)) {
      return alert("Please complete all required fields.");
    }

    return apiAddNewPartner(data);
  };

  const apiAddNewPartner = (data) => {
    Axios.post("/api/partner/sign-up", data)
      .then((res) => {
        const { success, message } = res.data;
        if (!success) return alert(message);
        return navigateTo("/partner/sign-in");
      })
      .catch((err) => console.error(err));
  };

  const handleEmailValidation = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(email)) {
      alert("Email format is invalid.");
      return false;
    } else return true;
  };

  const handlePasswordValidation = () => {
    if (password.length < 8 || password === "".trim()) {
      alert("Your password must be at least 8 characters long.");
      return false;
    } else return true;
  };

  const handleFirstNameInput = (e) => setFirstname(e.target.value);
  const handleLastNameInput = (e) => setLastname(e.target.value);
  const handleDateOfBirthInput = (e) => {
    const value = e.target.value;
    if (value.length > 10) return;
    return setDateOfBirth(value);
  };
  const handleChannelNameInput = (e) => setChannelName(e.target.value);

  const handleAboutInput = (e) => {
    const inputText = e.target.value;

    if (inputText.length <= MAX_CHAR_LIMIT) {
      setAbout(inputText);
      return setCharCount(MAX_CHAR_LIMIT - inputText.length);
    }
  };

  const handleAboutPaste = (e) => {
    e.preventDefault();
    const pasteText = e.clipboardData.getData("text");
    const currentText = about + pasteText;
    if (currentText.length <= MAX_CHAR_LIMIT) {
      setAbout(currentText);
      setCharCount(MAX_CHAR_LIMIT - currentText.length);
    } else {
      const truncatedText = pasteText.substring(
        0,
        MAX_CHAR_LIMIT - about.length
      );
      setAbout(about + truncatedText);
      setCharCount(0);
    }
  };

  return (
    <Main>
      <Content scroll={"scroll-Y"}>
        <ContentWrapper width={700}>
          {errorMessage && (
            <span
              style={{
                color: "red",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 16,
                borderRadius: 4,
                width: "fit-content",
                margin: "100px auto",
                background: "white",
              }}
            >
              {errorMessage}
            </span>
          )}

          {errorMessage === "" && (
            <Form>
              <Heading title={"Sign Up as Partner"} color={"black"} />

              <br />

              <span style={{ opacity: 0.7, fontSize: 13 }}>
                I already have an account?{" "}
                <button
                  type="button"
                  onClick={() => navigateTo("/partner/sign-in")}
                  className="access-form__link"
                >
                  Sign In
                </button>
              </span>

              <br />
              <br />

              <InputStack>
                <TextField
                  label="First Name"
                  value={firstName}
                  onChange={(e) => handleFirstNameInput(e)}
                />

                <TextField
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => handleLastNameInput(e)}
                />

                <TextField
                  type="date"
                  label="Date Of Birth"
                  value={dateOfBirth}
                  onChange={(e) => handleDateOfBirthInput(e)}
                  placeholder="e.g. 01/07/1980"
                />

                <br />

                <TextField
                  type="email"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="e.g. email@domain.com"
                />

                <TextField
                  type="password"
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Must have at least 8 characters"
                  showPasswordOption
                />

                <br />

                <TextField
                  label="Channel Name"
                  placeholder="Your channel name is publicly visible"
                  value={channelName}
                  onChange={(e) => handleChannelNameInput(e)}
                />

                <div className="textarea-wrapper">
                  <TextArea
                    label="Tell your audience more about your channel"
                    value={about}
                    required
                    onChange={handleAboutInput}
                    onPaste={handleAboutPaste}
                    placeholder="Write here.."
                    maxHeight={200}
                  />

                  <span className="custom-textarea-char-count">
                    {charCount} char. remaining
                  </span>
                </div>

                <div
                  className="signup_terms__wrapper"
                  style={{
                    border:
                      agreementChecked || agreementChecked === null
                        ? "" && agreementChecked === false
                        : "solid 2px red",
                  }}
                >
                  <CheckBox
                    onChange={() => setAgreementChecked((check) => !check)}
                    checked={agreementChecked}
                    label={
                      <span className="signup_terms__links">
                        I agree to the{" "}
                        <a
                          href={PartnerTermsAndConditions}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          terms & conditions
                        </a>{" "}
                        and{" "}
                        <a
                          rel="noopener noreferrer"
                          href={GeneralPolicyPrivacy}
                          target="_blank"
                        >
                          privacy policy
                        </a>{" "}
                      </span>
                    }
                  />
                </div>
              </InputStack>

              <br />

              <Button
                value="Sign Up"
                variant="fill"
                style={{ width: "100%" }}
                onClick={() =>
                  agreementChecked === null
                    ? setAgreementChecked(false)
                    : handleSubmit()
                }
              />

              <br />
            </Form>
          )}

          <br />
          <br />
          <br />
        </ContentWrapper>
      </Content>
    </Main>
  );
};

export default PartnerSignUpPage;
