import React, { useEffect, useState } from "react";
import { ConfirmedDialog } from "../../../../../components/dialog/Dialog";
import Axios from "../../../../../api/axios/Axios";
import {
  Form,
  InputStack,
  Heading,
} from "../../../../../components/layout/layout/Layout";
import { TextField } from "../../../../../components/input/Inputs";
import { Button } from "../../../../../components/button/Buttons";

function AdminPersonalDetailForm() {
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [toggleAlert, setToggleAlert] = useState(false);

  useEffect(() => apiGetPersonalDetails(), []);

  const apiGetPersonalDetails = () => {
    Axios.get("/select/admin/profile")
      .then((res) => {
        const admin = res.data[0];
        setFirstname(admin.firstName);
        setLastname(admin.lastName);
        return setDateOfBirth(admin.dateOfBirth);
      })
      .catch((err) => console.error(err));
  };

  const handleUpdateSubmit = () => {
    const data = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      dateOfBirth: dateOfBirth.trim(),
    };

    const obj = Object.values(data).every((value) => !!value);
    if (!obj) return alert("Invalid fields.");
    return apiUpdateProfile(data);
  };

  const apiUpdateProfile = (data) => {
    Axios.post("/update/admin/profile", data)
      .then((res) => {
        const { success } = res.data;
        if (success) return setToggleAlert(true);
      })
      .catch((err) => console.error(err));
  };

  // 📌 Handle input data:

  const handleFirstNameInput = (e) => {
    if (e.target.value.length <= 45) {
      return setFirstname(e.target.value);
    }
  };

  const handleLastNameInput = (e) => {
    if (e.target.value.length <= 45) {
      return setLastname(e.target.value);
    }
  };

  const handleDateOfBirth = (e) => {
    if (e.target.value.length <= 10) {
      return setDateOfBirth(e.target.value);
    }
  };

  return (
    <Form style={{ marginTop: 24 }}>
      <Heading title={"Personal Details"} color={"#171717"} bottom={16} />

      <InputStack>
        <TextField
          label={"First Name"}
          value={firstName}
          onChange={(e) => handleFirstNameInput(e)}
        />
        <TextField
          label="Last Name"
          value={lastName}
          onChange={(e) => handleLastNameInput(e)}
        />

        <TextField
          type="date"
          label="Date Of Birth"
          value={dateOfBirth}
          onChange={(e) => handleDateOfBirth(e)}
        />

        <Button
          value="Update Profile"
          variant="fill"
          style={{ marginTop: 15 }}
          onClick={() => handleUpdateSubmit()}
        />

        <br />
      </InputStack>

      {toggleAlert && (
        <ConfirmedDialog
          status="success"
          message="Updated"
          open={setToggleAlert}
        />
      )}
    </Form>
  );
}

export default AdminPersonalDetailForm;
