import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RiSearchLine } from "react-icons/ri";

const SlidingSearchBar = ({ isOpen }) => {
  const [searchValue, setSearchValue] = useState("");
  const navigateTo = useNavigate();
  const inputRef = useRef(null);

  useEffect(() => {
    isOpen && inputRef.current.focus();
  }, [isOpen]);

  const handleKeyPress = (e) => {
    if (searchValue.length === 0) return;
    if (e.key === "Enter") {
      isOpen();
      navigateTo("/search?q=" + searchValue);
    }
  };

  const handleOnClicked = () => {
    if (searchValue.length === 0) return;
    isOpen();
    navigateTo("/search?q=" + searchValue);
  };

  return (
    <div className="searchBar__container" onClick={(e) => e.stopPropagation()}>
      <div className="searchBar__wrapper">
        <input
          type="text"
          placeholder="Search archive games"
          ref={inputRef}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleKeyPress}
        />

        <RiSearchLine
          className="searchBar__icon"
          onClick={() => handleOnClicked()}
        />
      </div>
    </div>
  );
};

export default SlidingSearchBar;
