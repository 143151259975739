import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { LoadingDialog } from "../../../../components/dialog/Dialog";
import { objectDataValidation } from "../../../../hooks/Validation";
import Axios from "../../../../api/axios/Axios";
import { InputStack } from "../../../../components/layout/layout/Layout";
import Heading from "../../../../components/heading/Heading";
import {
  TextField,
  Select,
  TextArea,
} from "../../../../components/input/Inputs";
import { Button } from "../../../../components/button/Buttons";
import { FormatDateForInput } from "../../../../hooks/DateTime";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../../../components/layout/container/Container";

const PartnerEditVideoPage = () => {
  const [isData, setIsData] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(0);
  const [title, setTitle] = useState("");
  const [sport, setSport] = useState("");
  const [tournament, setTournament] = useState("");
  const [competitionLevel, setCompetitionLevel] = useState("");
  const [matchDate, setMatchDate] = useState("");
  const [description, setDescription] = useState("");
  const [replay, setReplay] = useState("");
  const [videoDuration, setVideoDuration] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [twoDayPrice, setTwoDayPrice] = useState("");
  const [fiveDayPrice, setFiveDayPrice] = useState("");

  const { videoId } = useParams();
  const navigateTo = useNavigate();

  useEffect(() => apiGetMatchDetails(), [videoId]);

  const apiGetMatchDetails = () => {
    const data = { videoId: videoId };

    Axios.post("/select/partner/video/match/edit", data)
      .then((res) => {
        const d = res.data[0];

        setTitle(d.title);
        setSport(d.sportType);
        setTournament(d.tournamentType);
        setCompetitionLevel(d.competitionLevel);
        setMatchDate(FormatDateForInput(d.matchDate));
        setDescription(d.description);
        setTwoDayPrice(d.twoDayPrice);
        setFiveDayPrice(d.fiveDayPrice);
        setVideoDuration(d.videoDuration);
        setReplay(d.replayType);
        setCurrentVideoId(d.videoId);

        if (d) return setIsData(true);
      })
      .catch((err) => console.error(err));
  };

  const handleSubmit = () => {
    if (!validateMinimumPriceAllow()) return;

    const data = {
      videoId: currentVideoId,
      title: title,
      sportType: sport,
      tournamentType: tournament,
      competitionLevel: competitionLevel,
      matchDate: matchDate,
      description: description,
      twoDayPrice: parseFloat(twoDayPrice).toFixed(2),
      fiveDayPrice: parseFloat(fiveDayPrice).toFixed(2),
      replayType: replay,
    };

    if (!objectDataValidation(data))
      return alert("Please complete all required fields.");

    return apiUpdateMatchDetails(data);
  };

  const apiUpdateMatchDetails = (data) => {
    Axios.post("/update/partner/video/match", data, {
      cache: false,
      contentType: false,
      processData: false,
      enctype: "multipart/form-data",
      onUploadProgress: () => setIsLoading(true),
    })
      .then((res) => {
        const { success } = res.data;
        if (success) {
          setIsLoading(false);
          return navigateTo("/partner/matches");
        }
      })
      .catch((err) => console.error(err));
  };

  const apiDeleteMatch = () => {
    Axios.post("/delete/partner/match", videoId)
      .then((res) => {
        return navigateTo("/partner/matches");
      })
      .catch((err) => console.error(err));
  };

  const handleDeleteMatch = () => {
    const msg = "Are you sure you want to delete this video?";
    const response = window.confirm(msg);
    if (response) {
      return apiDeleteMatch();
    } else return;
  };

  const validateMinimumPriceAllow = () => {
    const msg =
      "The set price must be equal to or greater than the minimum amount.";

    const alertPrompt = () => {
      alert(msg);
      return false;
    };

    const minimumPrices = {
      Juniors: { twoDay: 2.8, fiveDay: 5.6 },
      Seniors: { twoDay: 3.8, fiveDay: 7.6 },
      Mixed: { twoDay: 3.8, fiveDay: 7.6 },
    };

    const isValidPrice = (competitionLevel, priceType, price) =>
      parseFloat(price) >= minimumPrices[competitionLevel][priceType];

    if (!twoDayPrice || !fiveDayPrice) return alertPrompt();

    if (
      !isValidPrice(competitionLevel, "twoDay", twoDayPrice) ||
      !isValidPrice(competitionLevel, "fiveDay", fiveDayPrice)
    ) {
      return alertPrompt();
    }

    return true;
  };

  const toggleMininumPrice = (value) => {
    const prices = {
      Juniors: { twoDay: "2.80", fiveDay: "5.60" },
      Seniors: { twoDay: "3.80", fiveDay: "7.60" },
      Mixed: { twoDay: "3.80", fiveDay: "7.60" },
    };

    if (prices[value]) {
      setTwoDayPrice(prices[value].twoDay);
      return setFiveDayPrice(prices[value].fiveDay);
    }
  };

  // ---------
  // 📍Input:
  // ---------

  const handleTwoDayPriceInput = (e) => {
    const value = e.target.value;
    if (value.length <= 5) return setTwoDayPrice(value);
  };

  const handleSevenDayPriceInput = (e) => {
    const value = e.target.value;
    if (value.length <= 5) return setFiveDayPrice(value);
  };

  const handleTitleInput = (e) => {
    const value = e.target.value;
    if (value.length <= 100) return setTitle(value);
  };

  const handleDescriptionInput = (e) => {
    const value = e.target.value;
    if (value.length <= 300) return setDescription(value);
  };

  const handleSportInput = (value) => setSport(value);
  const handleTournamentInput = (value) => setTournament(value);
  const handleCompetitionLevelInput = (value) => {
    setCompetitionLevel(value);
    return toggleMininumPrice(value);
  };
  const handleReplayInput = (value) => setReplay(value);

  return (
    <Main backgroundColor={"white"}>
      <Content scroll={"scroll-Y"}>
        <ContentWrapper maxWidth={"largeWidth"}>
          <Heading title={"Edit Video"} color={"black"} />

          {isData && (
            <>
              <br />

              <InputStack>
                <TextField
                  type="text"
                  label="Title"
                  value={title}
                  onChange={handleTitleInput}
                />

                <InputStack direction={"row"}>
                  <Select
                    label="Sport"
                    value={sport}
                    items={[
                      { value: "Rugby Union" },
                      { value: "Rugby League" },
                    ]}
                    onChange={handleSportInput}
                  />

                  <TextField
                    label="Match Date"
                    type="date"
                    value={matchDate}
                    onChange={(e) => setMatchDate(e.target.value)}
                  />
                </InputStack>

                <InputStack direction={"row"}>
                  <Select
                    label="Tournament"
                    value={tournament}
                    items={[
                      { value: "XVs" },
                      { value: "10s" },
                      { value: "7s" },
                    ]}
                    onChange={handleTournamentInput}
                  />

                  <Select
                    label="Competition"
                    value={competitionLevel}
                    items={[
                      { value: "Seniors" },
                      { value: "Juniors" },
                      { value: "Mixed" },
                    ]}
                    onChange={handleCompetitionLevelInput}
                  />
                </InputStack>

                <InputStack direction={"row"}>
                  <Select
                    label="Replay"
                    value={replay}
                    items={[
                      { value: "Full Game" },
                      { value: "Part Game" },
                      { value: "Highlights" },
                      { value: "Mixed Clips" },
                    ]}
                    onChange={handleReplayInput}
                  />

                  <TextField
                    label="Video Duration (mins)"
                    type="text"
                    value={videoDuration}
                    placeholder="0"
                    disabled
                  />
                </InputStack>

                <InputStack direction={"row"}>
                  <TextField
                    label="Price 1 (2 day period)"
                    type="num"
                    value={twoDayPrice}
                    placeholder={`A min. amount of 2.90`}
                    onChange={(e) => handleTwoDayPriceInput(e)}
                  />

                  <TextField
                    label="Price 2 (7 day period)"
                    type="num"
                    value={fiveDayPrice}
                    placeholder={`A min. amount of 8.70`}
                    onChange={(e) => handleSevenDayPriceInput(e)}
                  />
                </InputStack>

                <TextArea
                  label="Description"
                  placeholder="Tell us more about this video.."
                  value={description}
                  required
                  onChange={(e) => handleDescriptionInput(e)}
                />
              </InputStack>

              <br />

              <Button
                variant="fill"
                style={{ width: "100%" }}
                onClick={() => handleSubmit()}
                value="Update Video Details"
              />

              <br />

              <Button
                value="Delete Video"
                variant="none"
                style={{ display: "flex", color: "red", marginLeft: "auto" }}
                onClick={() => handleDeleteMatch(videoId)}
              />

              <br />

              {isLoading && <LoadingDialog title="Updating.." />}
            </>
          )}
        </ContentWrapper>
      </Content>
    </Main>
  );
};

export default PartnerEditVideoPage;
