export function ConvertDateTimeToTimestamp(datetime) {
  let d = new Date(datetime);
  d.setTime(d.getTime() - d.getTimezoneOffset() * 60000);
  let timestamp = d.toISOString().slice(0, 19).replace("T", " ");

  return timestamp;
}

export const timestampToDatetimeDifference = (timestamp) => {
  // Convert timestamps to Date objects
  const date1 = new Date(timestamp);
  const date2 = new Date(Date.now());

  // Calculate the time difference in milliseconds
  const timeDifference = date2 - date1;

  const secondsDifference = timeDifference / 1000;
  const minutesDifference = secondsDifference / 60;
  const hoursDifference = minutesDifference / 60;
  const daysDifference = hoursDifference / 24;
  const monthsDifference = daysDifference / 12;

  const handleTimeDifference = () => {
    if (Math.round(secondsDifference) === 0) {
      return `just now`;
    } else if (Math.round(secondsDifference) < 60) {
      return `${Math.round(secondsDifference)} secs ago`;
    }

    if (Math.round(minutesDifference) === 1) {
      return `${Math.round(minutesDifference)} min ago`;
    } else if (Math.round(minutesDifference) < 60) {
      return `${Math.round(minutesDifference)} mins ago`;
    }

    if (Math.round(hoursDifference) === 1) {
      return `${Math.round(hoursDifference)} hr ago`;
    } else if (Math.round(hoursDifference) < 24) {
      return `${Math.round(hoursDifference)} hrs ago`;
    }

    if (Math.round(daysDifference) === 1) {
      return `${Math.round(daysDifference)} day ago`;
    } else if (Math.round(daysDifference) < 30) {
      return `${Math.round(daysDifference)} days ago`;
    }

    if (Math.round(monthsDifference) === 1) {
      return `${Math.round(monthsDifference)} mo ago`;
    } else if (Math.round(monthsDifference) < 12) {
      return `${Math.round(monthsDifference)} mos ago`;
    }
  };

  return handleTimeDifference();
};

export const futureDate = (numberOfDays) => {
  const date = new Date();
  const futureDate = date.setDate(date.getDate() + numberOfDays);
  const newDate = new Date(futureDate);
  const defaultExpiryDate = newDate.toJSON().split("T")[0];

  return defaultExpiryDate;
};

export const currentDate = () => {
  const date = new Date();
  const currentDate = date.toJSON().split("T")[0];

  return currentDate;
};

export const FormatDateForInput = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const FormatDateToLocaleDateString = (date) => {
  const d = new Date(date);
  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = d.toLocaleDateString("en-US", options);

  return formattedDate;
};

export const CalculateDaysInAdvance = (number) => {
  const numberOfDays = number;

  const today = new Date();
  const numOfDaysInMiliSeconds = numberOfDays * 24 * 60 * 60 * 1000;
  const datetimeInAdvance = new Date(today.getTime() + numOfDaysInMiliSeconds);

  return datetimeInAdvance;
};

export const FormatDatetimeToMySQL = (datetimeString) => {
  const datetime = new Date(datetimeString);
  const mysqlDatetime = datetime.toISOString().slice(0, 19).replace("T", " ");
  return mysqlDatetime;
};

export const CalculateTimeDifference = (datetimeString) => {
  const currentTime = new Date();
  const targetTime = new Date(datetimeString);
  const diff = currentTime - targetTime;

  if (diff < 24 * 60 * 60 * 1000) return true;

  return false;
};

export const AddDaysInAdvanceUsingTimestamp = (timestamp, numOfDays) => {
  const initialTimestamp = timestamp;
  const initialDate = new Date(initialTimestamp * 1000);

  const twoDaysInMilliseconds = numOfDays * 24 * 60 * 60 * 1000;
  const newDate = new Date(initialDate.getTime() + twoDaysInMilliseconds);

  const newTimestamp = Math.floor(newDate.getTime() / 1000);

  return newTimestamp;
};

export const CalculateDaysInAdvanceWithHours = (timestamp, hours) => {
  const initialTimestamp = timestamp;
  const initialDate = new Date(initialTimestamp * 1000);

  const numOfDays = hours / 24;

  const twoDaysInMilliseconds = numOfDays * 24 * 60 * 60 * 1000;
  const newDate = new Date(initialDate.getTime() + twoDaysInMilliseconds);

  const newTimestamp = Math.floor(newDate.getTime() / 1000);

  return newTimestamp;
};
