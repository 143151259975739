import React from "react";
import { useNavigate } from "react-router-dom";
import "./Footer.css";
import TermsOfUse from "../../../assets/docs/MemberTermsAndConditions.pdf";
import GeneralPolicyPrivacy from "../../../assets/docs/PrivacyPolicy.pdf";
// import PartnerTerms from "../../../assets/docs/PartnershipProgramTermsAndConditions.pdf";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const navigateTo = useNavigate();

  const TopFooter = () => {
    return (
      <div className="top-footer__container">
        <div className="top-footer_content__con">
          <div className="top-footer_legal-docs__con">
            <ul className="top-footer_legal-docs__ul">
              <li onClick={() => navigateTo("/")}>Home</li>
              <li>About</li>
              <li onClick={() => navigateTo("/contact/support")}>Contact Us</li>
            </ul>

            <ul className="top-footer_legal-docs__ul">
              <li>
                <a href={TermsOfUse} rel="noopener noreferrer" target="_blank">
                  Terms of Use
                </a>
              </li>

              <li>
                <a
                  href={GeneralPolicyPrivacy}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const BottomFooter = () => {
    return (
      <div
        style={{
          height: 64,
          padding: 24,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#aaaaaa",
          fontSize: "0.75rem",
          background: "transparent",
        }}
      >
        &copy; {currentYear} XREPLAY
      </div>
    );
  };

  return (
    <footer>
      <TopFooter />
      <BottomFooter />
    </footer>
  );
};

export default Footer;
