import React, { useState } from "react";
import Axios from "../../../../api/axios/Axios";
import { objectDataValidation } from "../../../../hooks/Validation";

import AdminProfileForm from "./form/PersonalDetail";
import AdminPasswordForm from "./form/PasswordForm";
import Filters from "./components/Filters";

import {
  Main,
  Content,
  ContentWrapper,
} from "../../../../components/layout/container/Container";

const MemberAccountPage = () => {
  const [visibility, setVisibility] = useState("personalDetailForm");
  const [memberId, setMemberId] = useState(null);

  const handleDeleteMemberAccount = () => {
    const msg =
      "Your account and watchlist videos will be permanently deleted. Are you sure you want to delete your account?";
    const response = window.confirm(msg);
    if (response) {
      const data = { memberId: memberId };
      if (!objectDataValidation(data)) return false;

      return apiDelete(data);
    } else return false;
  };

  const apiDelete = (data) => {
    Axios("/delete/member/account", data)
      .then((res) => {
        const { success } = res.data;
        if (success) return (window.location.href = "/");
      })
      .catch((err) => console.error(err));
  };

  const toggleVisibility = (status) => {
    return setVisibility(status);
  };

  const getMemberIdFromProps = (id) => {
    return setMemberId(id);
  };

  return (
    <Main>
      <Content scroll={"scroll-Y"}>
        <ContentWrapper width={700}>
          <Filters
            visibility={visibility}
            personalDetailFormOnClick={() =>
              toggleVisibility("personalDetailForm")
            }
            passwordFormOnClick={() => toggleVisibility("passwordForm")}
            deleteAccountOnClick={() => handleDeleteMemberAccount()}
          />

          <br />

          {visibility === "personalDetailForm" && (
            <AdminProfileForm passMemberId={getMemberIdFromProps} />
          )}

          {visibility === "passwordForm" && (
            <AdminPasswordForm passMemberId={getMemberIdFromProps} />
          )}
        </ContentWrapper>

        <br />
        <br />
        <br />
        <br />
      </Content>
    </Main>
  );
};

export default MemberAccountPage;
