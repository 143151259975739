export const BaseUrlConnection = () => {
  const APPLICATION_MODE = process.env.NODE_ENV.trim();

  if (APPLICATION_MODE === "development") {
    return process.env.REACT_APP_LOCAL_NETWORK_IP_ADDRESS;
  }

  if (APPLICATION_MODE === "production") {
    return process.env.REACT_APP_LIVE_NETWORK_IP_ADDRESS;
  }
};
