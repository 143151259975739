import React, { useEffect, useState } from "react";
import { ConfirmedDialog } from "../../../../../components/dialog/Dialog";
import Axios from "../../../../../api/axios/Axios";
import {
  Form,
  InputStack,
  Heading,
} from "../../../../../components/layout/layout/Layout";
import { TextField } from "../../../../../components/input/Inputs";
import { Button } from "../../../../../components/button/Buttons";

function AdminPasswordForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [toggleAlert, setToggleAlert] = useState(false);

  useEffect(() => apiGetUserEmail(), []);

  const apiGetUserEmail = () => {
    Axios.get("/select/admin/email")
      .then((res) => {
        const email = res.data[0].email;
        return setEmail(email);
      })
      .catch((err) => console.error(err));
  };

  const handleSubmit = () => {
    if (password.length < 8 || password === "")
      return alert("Password must be at least 8 characters long.");

    const data = { password: password.trim() };
    return apiUpdatePassword(data);
  };

  const apiUpdatePassword = (data) => {
    Axios.post("/update/admin/password", data)
      .then((res) => {
        const { success } = res.data;
        if (success) return setToggleAlert(true);
      })
      .catch((err) => console.error(err));
  };

  const handlePasswordInput = (e) => setPassword(e.target.value);

  return (
    <Form style={{ marginTop: 24 }}>
      <Heading title={"Update Password"} color={"#171717"} bottom={16} />

      <InputStack>
        <TextField label="Email" value={email} disabled />

        <TextField
          type="password"
          label="Password"
          value={password}
          placeholder="Must be at least 8 characters."
          onChange={handlePasswordInput}
        />

        <Button
          value="Update Password"
          variant="fill"
          onClick={(e) => handleSubmit(e)}
          style={{ margin: "24px 0 0 0" }}
        />

        <br />
      </InputStack>

      {toggleAlert && (
        <ConfirmedDialog
          status="success"
          message="Updated"
          open={setToggleAlert}
        />
      )}
    </Form>
  );
}

export default AdminPasswordForm;
