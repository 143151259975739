import React from "react";

const Filters = ({ activeOnClick, purchasedHistoryOnClick, visibility }) => {
  return (
    <ul className="main-filters__ul">
      <li
        className={visibility === "Active" ? "active" : null}
        onClick={() => activeOnClick()}
      >
        Active Videos
      </li>

      <li
        className={visibility === "History" ? "active" : null}
        onClick={() => purchasedHistoryOnClick()}
      >
        Purchase History
      </li>
    </ul>
  );
};

export default Filters;
