import React, { useEffect, useState } from "react";
import "./Player.css";
import ReactPlayer from "react-player";

const HorizontalVideoPlayer = ({ videoUrl, previewDuration, showControls }) => {
  const [playing, setPlaying] = useState(false);

  useEffect(() => handleAutoPlay(), [previewDuration, showControls]);

  const handleProgress = (progress) => {
    if (progress.playedSeconds >= previewDuration) return setPlaying(false);
  };

  const handleAutoPlay = () => {
    if (showControls === false) return setPlaying(false);

    if (previewDuration || showControls === true) {
      setTimeout(() => setPlaying(true), 2000);
    }
  };

  const handleToggleControls = () => {
    if (previewDuration) return false;
    if (showControls) return true;
  };

  return (
    <ReactPlayer
      className="react-player"
      url={videoUrl}
      width="100%"
      height="100%"
      controls={handleToggleControls()}
      playing={playing}
      loop={true}
      preload="auto"
      muted={true}
      onProgress={previewDuration && handleProgress}
      playsinline
      config={{
        file: {
          attributes: {
            controlsList: "nodownload",
            onContextMenu: (e) => e.preventDefault(),
          },
        },
      }}
    />
  );
};

export default HorizontalVideoPlayer;
