import React, { useEffect, useState } from "react";
import { TextField } from "../../../../../components/input/Inputs";
import { Button } from "../../../../../components/button/Buttons";
import Axios from "../../../../../api/axios/Axios";
import {
  InputStack,
  Form,
} from "../../../../../components/layout/layout/Layout";
import Heading from "../../../../../components/heading/Heading";
import { objectDataValidation } from "../../../../../hooks/Validation";
import { ConfirmedDialog } from "../../../../../components/dialog/Dialog";

const PartnerPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [toggleAlert, setToggleAlert] = useState(false);

  useEffect(() => apiGetAccountDetails(), []);

  const apiGetAccountDetails = () => {
    Axios.get("/api/partner/select/account-detail")
      .then((res) => {
        const data = res.data[0];
        return setEmail(data.email);
      })
      .catch((err) => console.error(err));
  };

  const handleSubmit = () => {
    handlePasswordValidation();

    const data = { password: password };

    if (!objectDataValidation(data))
      return alert("Please complete all required fields.");

    return apiUpdatePassword(data);
  };

  const apiUpdatePassword = (data) => {
    Axios.post("/api/partner/update/password", data)
      .then((res) => {
        const { success } = res.data;
        if (success) return setToggleAlert(true);
      })
      .catch((err) => console.error(err));
  };

  const handlePasswordValidation = () => {
    if (password.length < 8) {
      return alert("Your password must be at least 8 characters long.");
    } else return true;
  };

  const handlePasswordInput = (e) => {
    const value = e.target.value;
    return setPassword(value);
  };

  return (
    <Form>
      <Heading title={"Change Password"} color={"black"} />

      <br />
      <br />

      <InputStack>
        <TextField type="email" label="Email" disabled value={email} />

        <TextField
          type="password"
          label="New Password"
          placeholder="Must have at least 8 characters"
          onChange={handlePasswordInput}
        />
      </InputStack>

      <br />

      <Button
        value="Update Password"
        variant="fill"
        onClick={() => handleSubmit()}
        style={{ width: "100%" }}
      />

      {toggleAlert && (
        <ConfirmedDialog
          status="success"
          message="Updated"
          open={setToggleAlert}
        />
      )}

      <br />
    </Form>
  );
};

export default PartnerPasswordForm;
