import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SportCard } from "../../../components/cards/Cards";
import Axios from "../../../api/axios/Axios";
import {
  timestampToDatetimeDifference,
  CalculateTimeDifference,
} from "../../../hooks/DateTime";
import { ListGrid } from "../../../components/layout/layout/Layout";
import Heading from "../../../components/heading/Heading";
import "./View.css";

const MatchListView = ({ isLoggedIn, userType, filteredValue }) => {
  const [videoData, setVideoData] = useState([]);

  const navigateTo = useNavigate();

  useEffect(() => {
    return apiGetActiveMatchesList();
  }, [filteredValue]);

  const apiGetActiveMatchesList = () => {
    Axios.get("/api/public/select/videos-list", {
      params: { offSet: 0, filteredValue: filteredValue },
    })
      .then((res) => {
        const data = res.data;
        if (data.length === 0) return setVideoData([]);
        return setVideoData(data);
      })
      .catch((err) => console.error(err.message));
  };

  const handleChannelClicked = (userName) => {
    if (isLoggedIn && userType !== "Member")
      return alert("Member access only.");
    return navigateTo(`/${userName}`);
  };

  const handleSelectedMatchOnClick = (videoId, partnerId) => {
    if (isLoggedIn && userType !== "Member") {
      return alert("Member access only!");
    }
    return navigateTo(`/watch/${videoId}/${partnerId}`);
  };

  const MatchList = () => {
    const MemoItemList = useMemo(() => {
      return (
        <>
          {videoData.length > 0 && (
            <section>
              <Heading title={"Replays"} />

              <br />
              <br />

              <ListGrid>
                {videoData.map((video, index) => (
                  <SportCard
                    key={index}
                    title={video.title}
                    duration={video.duration}
                    thumbnail={video.thumbnail}
                    avatar={video.channelName}
                    channelName={video.channelName}
                    createdAt={timestampToDatetimeDifference(video.createdAt)}
                    newLabelNotification={CalculateTimeDifference(
                      video.createdAt
                    )}
                    channelClicked={() => {
                      handleChannelClicked(video.userName);
                    }}
                    videoClicked={() => {
                      return handleSelectedMatchOnClick(
                        video.videoId,
                        video.partnerId
                      );
                    }}
                    mainContainer="home-archive-listing__card"
                  />
                ))}
              </ListGrid>
            </section>
          )}
        </>
      );
    }, [videoData]);

    return MemoItemList;
  };

  return <MatchList />;
};

export default MatchListView;
