import React from "react";
import "./Modal.css";
import { RiCloseFill } from "react-icons/ri";

const Modal = ({ children, openModal, closeModal }) => {
  const handleClosingModal = () => closeModal(false);

  return (
    <>
      {openModal && (
        <div className="custom-general-modal">
          <button
            className="custom-general-modal__close-btn"
            onClick={handleClosingModal}
          >
            <RiCloseFill className="custom-general-modal__close-icon" />
          </button>
          {children}
        </div>
      )}
    </>
  );
};

export default Modal;
