import React, { useState, useEffect } from "react";
import { MdOutlineVideocamOff } from "react-icons/md";

const NoDataDialog = ({ title, message, color }) => {
  const [show, setShow] = useState(false);
  useEffect(() => timer(), [show]);
  const timer = () => {
    setTimeout(() => setShow(true), 1000);
  };

  return (
    <>
      {show && (
        <div className="noData__con" style={{ color: color }}>
          <MdOutlineVideocamOff className="noData__icon" />
          <p className="noData__title">{title}</p>
          <p className="noData__message">
            {message ? message : "No data found"}
          </p>
        </div>
      )}
    </>
  );
};

export default NoDataDialog;
