import React, { useState } from "react";
import Axios from "../../api/axios/Axios";
import { TextField, TextArea, Select } from "../../components/input/Inputs";
import { Button } from "../../components/button/Buttons";
import {
  Form,
  InputStack,
  Heading,
} from "../../components/layout/layout/Layout";

import {
  Main,
  Content,
  ContentWrapper,
} from "../../components/layout/container/Container";

import { objectDataValidation } from "../../hooks/Validation";

const SupportForm = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("General");
  const [other, setOther] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    if (!handleEmailValidation()) return;

    const data = {
      fullName: fullName,
      email: email,
      subject: subject,
      other: other ? other : "N/A",
      message: message,
    };

    if (!objectDataValidation(data))
      return alert("Please complete all required fields.");

    sendQueryRequest(data);
  };

  const sendQueryRequest = (data) => {
    Axios.post("/contact/support", data, {
      onUploadProgress: () => setIsLoading(true),
    })
      .then((res) => {
        const { success } = res.data;

        const msg = `Thank you for contacting us. We'll get back to you within 48 hours.
          Your patience is appreciated and we'll be in touch soon!`;

        if (success) return setSuccessMessage(msg);
      })
      .catch((err) => console.error(err));
  };

  const handleEmailValidation = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailPattern.test(email)) {
      return alert("Email format is invalid.");
    } else return true;
  };

  const handleFirstNameInput = (e) => {
    if (e.target.value.length >= 45) return;
    setFullName(e.target.value);
  };

  const handleSelectedSubject = (value) => setSubject(value);

  const SuccessMessage = () => {
    return (
      <div style={{ padding: 16, borderRadius: 8 }}>
        <p style={{ fontSize: "1.5rem", fontWeight: 600, marginBottom: 12 }}>
          Submitted
        </p>
        <p>{successMessage}</p>
      </div>
    );
  };

  return (
    <Main>
      <Content>
        <ContentWrapper width={700}>
          <Form>
            {successMessage === "" ? (
              <>
                <Heading title={"Contact Us"} color={"#171717"} bottom={24} />

                <InputStack>
                  <TextField
                    label="Full Name"
                    value={fullName}
                    onChange={(e) => handleFirstNameInput(e)}
                  />

                  <TextField
                    type="email"
                    label="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="e.g. email@domain.com"
                  />

                  <Select
                    label="Subject"
                    value={subject}
                    items={[
                      { value: "General" },
                      { value: "Technical support" },
                      { value: "Queries about payments" },
                      { value: "I can't log in" },
                      { value: "Copyright infringement report" },
                      { value: "I can't watch videos" },
                      { value: "Account recovery" },
                      { value: "Privacy concerns" },
                      { value: "I want to delete my account and data" },
                      { value: "Feedback submission" },
                      { value: "Other" },
                    ]}
                    onChange={handleSelectedSubject}
                  />

                  {subject === "Other" && (
                    <TextField
                      label="Other"
                      value={other}
                      placeholder="e.g. Write related subject"
                      onChange={(e) => setOther(e)}
                    />
                  )}

                  <TextArea
                    label="Message"
                    placeholder="Write us a message"
                    value={message}
                    rows={5}
                    onChange={(e) => setMessage(e.target.value)}
                  />

                  <Button
                    disabled={isLoading ? true : false}
                    value="Submit"
                    variant="fill"
                    onClick={() => handleSubmit()}
                    style={{ margin: "24px 0 24px 0" }}
                  />
                </InputStack>
              </>
            ) : (
              <SuccessMessage />
            )}
          </Form>
        </ContentWrapper>
      </Content>
    </Main>
  );
};

export default SupportForm;
