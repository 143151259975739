import React from "react";
import AdminMatchesList from "./view/MatchesList";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../../../components/layout/container/Container";

const AdminMatchesPage = () => {
  return (
    <Main>
      <Content scroll={"scroll-Y"}>
        <ContentWrapper >
          <AdminMatchesList />
        </ContentWrapper>
      </Content>
    </Main>
  );
};

export default AdminMatchesPage;
