import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "../../api/axios/Axios";
import MatchPreviewPage from "./MatchPreview";
import MatchReplayPage from "./FullMatchReplay";

const WatchReplayPage = ({ isLoggedIn }) => {
  const [videoData, setVideoData] = useState(null);
  const [togglePlayer, setTogglePlayer] = useState(null);
  const { videoId, partnerId } = useParams();

  useEffect(() => apiCheckSelectedVideoStatus(), [videoId, partnerId]);

  const handleToggleReplay = (data) => {
    if (data.replay === "PREVIEW_ONLY") {
      setVideoData(data);
      return setTogglePlayer(data.replay);
    }

    if (data.replay === "FULL_REPLAY") {
      setVideoData(data);
      return setTogglePlayer(data.replay);
    }
  };

  const apiCheckSelectedVideoStatus = () => {
    Axios.get("/api/select/check-video-status", {
      params: { videoId: videoId, partnerId: partnerId },
    })
      .then((res) => {
        const data = res.data[0];
        return handleToggleReplay(data);
      })
      .catch((err) => {
        console.error(err);
        if (err) window.location.href = "/error/404";
      });
  };

  return (
    <>
      {togglePlayer === "PREVIEW_ONLY" && (
        <MatchPreviewPage data={videoData} isLoggedIn={isLoggedIn} />
      )}
      {togglePlayer === "FULL_REPLAY" && (
        <MatchReplayPage data={videoData} isLoggedIn={isLoggedIn} />
      )}
    </>
  );
};

export default WatchReplayPage;
