import React from "react";
import { FormatDateToLocaleDateString } from "../../../../../hooks/DateTime";
import { RiEditLine } from "react-icons/ri";
import "../../../../../components/styles/Table.css";
import { ConvertFileSize } from "../../../../../hooks/ConvertFileSize";

const Table = ({ data, iconOnClick }) => {
  const Thumbnail = ({ thumb }) => {
    return (
      <div style={{ width: 40, height: 30 }}>
        <img
          style={{ width: "100%", height: "100%" }}
          src={thumb}
          alt="thumb"
        />
      </div>
    );
  };

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th className="table-th__icon"></th>
            <th className="table-th__thumbnail"></th>
            <th className="table-th__title">Title</th>
            <th>Duration</th>
            <th>Size</th>
            <th>Match Date</th>
            <th>Price (1d)</th>
            <th>Price (5d)</th>
            <th>Uploaded</th>
            <th>Partner Email</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item) => (
            <tr key={item.videoId}>
              <td className="table-td__icon">
                <button
                  className="admin-table-view_action__btn"
                  onClick={() =>
                    iconOnClick({
                      videoId: item.videoId,
                      partnerId: item.partnerId,
                    })
                  }
                >
                  <RiEditLine className="admin-table-view__icon" />
                </button>
              </td>
              <td>
                <Thumbnail
                  thumb={item.thumbnail}
                  duration={item.videoDuration}
                />
              </td>
              <td className="table-td__title">{item.title}</td>
              <td className="table-td__duration">{item.videoDuration}</td>
              <td>{ConvertFileSize(item.fileSize)}</td>
              <td>{FormatDateToLocaleDateString(item.matchDate)}</td>
              <td>{item.twoDayPrice}</td>
              <td>{item.fiveDayPrice}</td>
              <td>{FormatDateToLocaleDateString(item.uploadedAt)}</td>
              <td>{item.partnerEmail}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
