import React, { useState } from "react";
import "./Select.css";

const Select = (props) => {
  const [inputValue, setInputValue] = useState(props.value);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    props.onChange(value);
  };
  return (
    <div className="c_select__container">
      <label className="c_select__label">
        {props.label ? props.label : "Label"}
      </label>
      <select
        className="c__select"
        onChange={handleInputChange}
        value={inputValue}
      >
        {props.items &&
          props.items.map((item, i) => {
            return (
              <option key={i} value={item.value}>
                {item.value}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default Select;
