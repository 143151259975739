import React, { useState } from "react";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../../../components/layout/container/Container";
import PersonalDetailForm from "./form/PersonalDetail";
import PasswordForm from "./form/Password";
import BankingForm from "./form/Banking";
import Filters from "./components/Filters";

const PartnerAccountPage = () => {
  const [visibility, setVisibility] = useState("personalDetailForm");

  const toggleVisibility = (status) => {
    return setVisibility(status);
  };

  const handleDeleteButton = () => {
    const msg =
      "Please contact the RugbyGo administrator to request the deletion of your partnership account.";
    return alert(msg);
  };

  return (
    <Main>
      <Content scroll={"scroll-Y"}>
        <ContentWrapper width={700} position={"center"}>
          <Filters
            visibility={visibility}
            personalDetailFormOnClick={() =>
              toggleVisibility("personalDetailForm")
            }
            bankingFormOnClick={() => toggleVisibility("bankingForm")}
            passwordFormOnClick={() => toggleVisibility("passwordForm")}
            deleteAccountOnClick={() => handleDeleteButton()}
          />

          <br />

          {visibility === "personalDetailForm" && <PersonalDetailForm />}
          {visibility === "bankingForm" && <BankingForm />}
          {visibility === "passwordForm" && <PasswordForm />}
        </ContentWrapper>
        <br />
        <br />
        <br />
      </Content>
    </Main>
  );
};

export default PartnerAccountPage;
