import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../../../../../api/axios/Axios";
import { SportCard } from "../../../../../components/cards/Cards";
import { ListGrid } from "../../../../../components/layout/layout/Layout";
import { NoDataDialog } from "../../../../../components/dialog/Dialog";
import { timestampToDatetimeDifference } from "../../../../../hooks/DateTime";

const MemberWatchList = ({ isLoggedIn, userType }) => {
  const [videoData, setVideoData] = useState([]);

  const navigateTo = useNavigate();

  useEffect(() => apiGetActiveVideoList(), []);

  const apiGetActiveVideoList = () => {
    Axios.get("/api/member/select/active-rentals", { params: { offSet: 0 } })
      .then((res) => {
        const data = res.data;
        return setVideoData(data);
      })
      .catch((err) => console.error(err));
  };

  const handleChannelClicked = (userName) => {
    if (isLoggedIn && userType !== "Member") {
      return alert("Member access only.");
    }
    return navigateTo(`/${userName}`);
  };

  const handleToggleRentalExpiryStatus = (numOfDays) => {
    if (numOfDays === null) return "Watch Now";
    if (numOfDays === 0) return "Expires Soon";

    return `Expires in ${numOfDays}d`;
  };

  const handleSelectedMatchOnClick = (videoId, partnerId) => {
    return (window.location.href = `/watch/${videoId}/${partnerId}`);
  };

  const WatchList = () => {
    const MemoItemList = useMemo(() => {
      return (
        <>
          <span style={{ color: "white", fontSize: 12, color: "gray" }}>
            Only your active purchases will appear on your watchlist.
          </span>

          <br />

          {videoData.length > 0 && (
            <section>
              <ListGrid>
                {videoData.map((video, index) => (
                  <SportCard
                    key={index}
                    title={video.title}
                    duration={video.videoDuration}
                    thumbnail={video.thumbnail}
                    avatar={video.channelName}
                    channelName={video.channelName}
                    createdAt={timestampToDatetimeDifference(video.createdAt)}
                    expiresIn={handleToggleRentalExpiryStatus(video.expiresIn)}
                    videoClicked={() => {
                      handleSelectedMatchOnClick(
                        video.videoId,
                        video.partnerId
                      );
                    }}
                    channelClicked={() => {
                      handleChannelClicked(video.userName);
                    }}
                  />
                ))}
              </ListGrid>
            </section>
          )}
        </>
      );
    }, [videoData]);

    return MemoItemList;
  };

  return (
    <>
      {videoData.length > 0 ? (
        <WatchList />
      ) : (
        <NoDataDialog
          title="Watchlist"
          message={"Only your active purchases will appear on your watchlist."}
        />
      )}
    </>
  );
};

export default MemberWatchList;
