import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SportCard } from "../../components/cards/Cards";
import { ListGrid } from "../../components/layout/layout/Layout";
import Axios from "../../api/axios/Axios";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../components/layout/container/Container";
import "./Channel.css";
import {
  CalculateTimeDifference,
  timestampToDatetimeDifference,
} from "../../hooks/DateTime";

import loading from "../../assets/gif/spinner.gif";

const PartnerChannel = ({ isLoggedIn, userType }) => {
  const [creatorData, setCreatorData] = useState(null);
  const [videoData, setVideoData] = useState([]);
  const [totalOffsetItems, setTotalOffsetItems] = useState(0);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const navigateTo = useNavigate();
  const { userName } = useParams();

  useEffect(() => handleUnauthorisedUser(), [isLoggedIn, userType]);

  useEffect(() => {
    if (userName) {
      apiGetChannelDetail(userName);
      return apiGetAllChannelMatches(userName);
    }
  }, [userName]);

  useEffect(() => {
    if (videoData.length > 0) handleShowLoadMoreButton();
  }, [videoData]);

  const handleUnauthorisedUser = () => {
    if (isLoggedIn && userType === "Partner") return navigateTo("/");
  };

  const apiGetChannelDetail = () => {
    Axios.get("/select/partner/info", { params: { userName: userName } })
      .then((res) => {
        const data = res.data;
        if (data.length <= 0) return navigateTo("/error/404");
        return setCreatorData(data[0]);
      })
      .catch((err) => console.error(err));
  };

  const apiGetAllChannelMatches = (userName) => {
    Axios.get("/select/partner/channel", {
      params: { userName: userName, offSet: totalOffsetItems },
    })
      .then((res) => {
        const data = res.data;
        setVideoData([...videoData, ...data]);
        return setShowSpinner(false);
      })
      .catch((err) => console.error(err.message));
  };

  const handleSelectedMatchOnClick = (videoId, partnerId) => {
    return (window.location.href = `/watch/${videoId}/${partnerId}`);
  };

  const ChannelDetail = () => {
    return (
      <>
        {creatorData && (
          <div className="channel-owner__container">
            <span className="channel-owner__span">
              <div className="channel-owner__avator">
                {creatorData.channelName.charAt(0)}
              </div>

              <div className="channel-owner__detail">
                <p className="channel-owner__name">{creatorData.channelName}</p>
                <span className="channel-owner__joined">
                  @{creatorData.userName} • {creatorData.totalVideos} videos
                </span>
              </div>
            </span>
          </div>
        )}
      </>
    );
  };

  const handleLoadMoreButton = () => {
    const totalLoadedItems = parseInt(videoData.length);
    const overallTotalItems = parseInt(creatorData.totalVideos);

    if (totalLoadedItems < overallTotalItems) {
      setTotalOffsetItems(totalLoadedItems + overallTotalItems);
      setShowSpinner(true);
      return apiGetAllChannelMatches(userName);
    }
  };

  const handleShowLoadMoreButton = () => {
    const totalLoadedItems = parseInt(videoData.length);
    const overallTotalItems = parseInt(creatorData.totalVideos);

    if (totalLoadedItems < overallTotalItems) {
      setShowLoadMoreButton(true);
    } else {
      setShowLoadMoreButton(false);
    }
  };

  const MatchesList = () => {
    const MemoItemList = useMemo(() => {
      return (
        <>
          {videoData.length > 0 && (
            <section>
              <ListGrid>
                {videoData.map((video, index) => (
                  <SportCard
                    key={index}
                    title={video.title}
                    duration={video.duration}
                    thumbnail={video.thumbnail}
                    avatar={video.channelName}
                    createdAt={timestampToDatetimeDifference(video.createdAt)}
                    newLabelNotification={CalculateTimeDifference(
                      video.createdAt
                    )}
                    videoClicked={() => {
                      handleSelectedMatchOnClick(
                        video.videoId,
                        video.partnerId
                      );
                    }}
                  />
                ))}
              </ListGrid>
            </section>
          )}

          {showLoadMoreButton && (
            <button
              className="channel_load-more__btn"
              onClick={(e) => {
                e.stopPropagation();
                handleLoadMoreButton();
              }}
              disabled={showSpinner}
            >
              {showSpinner && (
                <img
                  src={loading}
                  alt="spinner"
                  className="channel_load-spinner__gif"
                />
              )}
              Load More
            </button>
          )}
        </>
      );
    }, [videoData]);

    return MemoItemList;
  };

  return (
    <Main>
      <Content scroll={"scroll-Y"}>
        <ContentWrapper>
          <ChannelDetail />

          <br />

          <MatchesList />

          <br />
          <br />
          <br />
        </ContentWrapper>
      </Content>
    </Main>
  );
};

export default PartnerChannel;
