import React, { useState, useEffect } from "react";
import { LoadingDialog } from "../../../../../components/dialog/Dialog";
import { objectDataValidation } from "../../../../../hooks/Validation";
import Axios from "../../../../../api/axios/Axios";
import {
  InputStack,
  Form,
} from "../../../../../components/layout/layout/Layout";
import Heading from "../../../../../components/heading/Heading";
import { TextLabel, Select } from "../../../../../components/input/Inputs";
import { Button } from "../../../../../components/button/Buttons";
import HorizontalVideoPlayer from "../../../../../components/player/horizontal/HorizontalVideoPlayer";
import { FormatDateToLocaleDateString } from "../../../../../hooks/DateTime";

const MatchReviewForm = ({
  closeForm,
  refreshVideoList,
  refreshVideoCount,
  selectedItemId,
}) => {
  const [isData, setIsData] = useState(false);
  const [title, setTitle] = useState("");
  const [sport, setSport] = useState("");
  const [tournament, setTournament] = useState("");
  const [competitionLevel, setCompetitionLevel] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [description, setDescription] = useState("");
  const [twoDayPrice, setTwoDayPrice] = useState("");
  const [fiveDayPrice, setFiveDayPrice] = useState("");
  const [replay, setReplay] = useState("");
  const [videoDuration, setVideoDuration] = useState("");
  const [reviewStatus, setReviewStatus] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [channelName, setChannelName] = useState("");

  useEffect(() => {
    if (selectedItemId) apiGetMatchForReview();
  }, [selectedItemId]);

  const apiGetMatchForReview = () => {
    const params = {
      videoId: selectedItemId.videoId,
      partnerId: selectedItemId.partnerId,
    };

    Axios.get("/select/admin/match/review", { params })
      .then((res) => {
        const d = res.data[0];

        setTitle(d.title);
        setSport(d.sportType);
        setTournament(d.tournamentType);
        setCompetitionLevel(d.competitionLevel);
        setEventDate(FormatDateToLocaleDateString(d.eventDate));
        setDescription(d.description);
        setTwoDayPrice(d.twoDayPrice);
        setFiveDayPrice(d.fiveDayPrice);
        setVideoDuration(d.videoDuration);
        setReplay(d.replayType);
        setVideoUrl(d.videoUrl);
        setReviewStatus(d.reviewStatus);
        setChannelName(d.channelName);

        if (d) setIsData(true);

        return;
      })
      .catch((err) => console.error(err));
  };

  const handleSubmit = () => {
    const data = {
      videoId: selectedItemId.videoId,
      partnerId: selectedItemId.partnerId,
      reviewStatus: reviewStatus,
      title: title,
      channelName: channelName,
      reviewDate: new Date(),
    };

    if (!objectDataValidation(data))
      return alert("Please complete all required fields.");

    return apiUpdateMatchReviewStatus(data);
  };

  const apiUpdateMatchReviewStatus = (data) => {
    Axios.post("/update/admin/match/review", data, {
      onUploadProgress: () => setIsLoading(true),
    })
      .then((res) => {
        const { success } = res.data;
        if (success) {
          refreshVideoCount();
          refreshVideoList();
          setIsLoading(false);
          closeForm(false);
          return;
        }
      })
      .catch((err) => console.error(err));
  };

  const handleReviewStatus = (value) => setReviewStatus(value);

  const ToggleReviewStatus = () => {
    if (reviewStatus === "In Review") return "Review Match";
    if (reviewStatus === "Declined") return "Decline Match";
    if (reviewStatus === "Published") return "Publish Match";
  };

  const apiDeleteMatch = () => {
    const data = {
      videoId: selectedItemId.videoId,
      partnerId: selectedItemId.partnerId,
    };

    Axios.post("/delete/partner/match", data)
      .then((res) => {
        closeForm();
        refreshVideoList();
        return refreshVideoCount();
      })
      .catch((err) => console.error(err));
  };

  const handleDeleteMatch = () => {
    const msg = "Are you sure you want to delete this video?";
    const response = window.confirm(msg);
    if (response) {
      return apiDeleteMatch();
    } else return;
  };

  return (
    <>
      {isData && (
        <Form
          width={700}
          style={{
            postion: "relative",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Heading title={"Match Review"} color={"black"} />

          <br />
          <br />

          <InputStack>
            <HorizontalVideoPlayer
              videoUrl={videoUrl}
              styles={{ width: "100%", height: 360 }}
            />

            <TextLabel label={"Title:"} value={title} row />
            <TextLabel label={"Sport:"} value={sport} row />
            <TextLabel label={"Match Date:"} value={eventDate} row />
            <TextLabel label={"Tournament:"} value={tournament} row />
            <TextLabel label={"Competition:"} value={competitionLevel} row />
            <TextLabel label={"Replay:"} value={replay} row />
            <TextLabel label={"Duration:"} value={videoDuration} row />
            <TextLabel
              label={"Price 1 (2 day period):"}
              value={twoDayPrice}
              row
            />
            <TextLabel
              label={"Price 2 (5 day period):"}
              value={fiveDayPrice}
              row
            />
            <TextLabel label={"Description:"} value={description} row />

            <Select
              label="Review Status"
              value={reviewStatus}
              items={[
                { value: "In Review" },
                { value: "Published" },
                { value: "Declined" },
              ]}
              onChange={handleReviewStatus}
            />
          </InputStack>

          <br />

          <Button
            variant="fill"
            style={{ width: "100%" }}
            onClick={() => handleSubmit()}
            value={ToggleReviewStatus()}
          />

          <br />

          <Button
            value="Delete Match"
            variant="link"
            style={{ color: "red", fontWeight: 500, marginLeft: "auto" }}
            onClick={() => handleDeleteMatch(selectedItemId)}
          />

          <br />

          {isLoading && <LoadingDialog title="Updating.." />}
        </Form>
      )}
    </>
  );
};

export default MatchReviewForm;
