import React, { useEffect, useState } from "react";
import "./Filters.css";
import {
  PiShareFatBold,
  PiCopySimpleFill,
  PiBookmarkSimpleBold,
  PiBookmarkSimpleFill,
} from "react-icons/pi";
import Axios from "../../../api/axios/Axios";

const Filters = ({ videoId, partnerId, isLoggedIn }) => {
  const [filtersData, setFiltersData] = useState([]);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => apiGetSavedVideoStatus(), [videoId]);

  const apiGetSavedVideoStatus = () => {
    Axios.get("/api/member/select/saved-video-status", {
      params: { videoId: videoId, partnerId: partnerId },
    })
      .then((res) => {
        const data = res.data;
        return setFiltersData(data);
      })
      .catch((err) => console.error(err));
  };

  const apiSaveVideo = () => {
    Axios.post("/api/member/update/save-video", {
      videoId: videoId,
      partnerId: partnerId,
      type: "Save",
      status: true,
    })
      .then((res) => {
        return apiGetSavedVideoStatus();
      })
      .catch((err) => console.error(err));
  };

  const toggleSaveFilterStatus = () => {
    let isSaved;

    filtersData.forEach((item) => {
      if (item.type === "Save" && item.hasSaved) {
        return (isSaved = true);
      } else return (isSaved = false);
    });

    return isSaved;
  };

  const handleShareFilterOnClick = async () => {
    const url = window.location.href;

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(url)
        .then(() => setIsCopied(true))
        .catch((err) => console.error("Failed to copy URL: ", err));
    } else {
      // Fallback for browsers that do not support the Clipboard API
      const textArea = document.createElement("textarea");
      textArea.value = url;
      textArea.style.position = "fixed";
      textArea.style.opacity = "0";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      setIsCopied(true);

      try {
        document.execCommand("copy");
        return setIsCopied(true);
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }

      return document.body.removeChild(textArea);
    }
  };

  return (
    <div className="home-search-filters__content filters-match-preview__con">
      <ul className="home-search-filters filters-match-preview__ul">
        <li onClick={() => handleShareFilterOnClick()}>
          {!isCopied ? (
            <>
              <PiShareFatBold className="filters-match-preview__icon" /> Share
            </>
          ) : (
            <>
              <PiCopySimpleFill className="filters-match-preview__icon" />{" "}
              Copied
            </>
          )}
        </li>

        {isLoggedIn && (
          <li onClick={() => apiSaveVideo()}>
            {toggleSaveFilterStatus() ? (
              <>
                <PiBookmarkSimpleFill className="filters-match-preview__icon" />{" "}
                Saved
              </>
            ) : (
              <>
                <PiBookmarkSimpleBold className="filters-match-preview__icon" />{" "}
                Save
              </>
            )}
          </li>
        )}
      </ul>
    </div>
  );
};

export default Filters;
