import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SavedItems.css";
import {
  Main,
  Content,
  ContentWrapper,
} from "../../../../components/layout/container/Container";
import Heading from "../../../../components/heading/Heading";
import Axios from "../../../../api/axios/Axios";
import { FormatDateToLocaleDateString } from "../../../../hooks/DateTime";
import { PiTrashSimpleBold } from "react-icons/pi";
import { NoDataDialog } from "../../../../components/dialog/Dialog";

const SavedItemsPage = () => {
  const [savedVideosData, setSavedVideosData] = useState([]);

  const navigateTo = useNavigate();

  useEffect(() => apiGetSavedVideos(), []);

  const apiGetSavedVideos = () => {
    Axios.get("/api/member/select/all/saved-videos")
      .then((res) => {
        const data = res.data;
        return setSavedVideosData(data);
      })
      .catch((err) => console.error(err));
  };

  const handleTitleOnClick = (videoId, partnerId) => {
    if (videoId) return navigateTo(`/watch/${videoId}/${partnerId}`);
  };

  const handleChannelOnClick = (userName) => {
    if (userName) return navigateTo(`/${userName}`);
  };

  const handleRemoveVideoOnClick = (videoId) => {
    if (videoId) return apiRemoveSavedVideo(videoId);
  };

  const apiRemoveSavedVideo = (videoId) => {
    Axios.post("/api/member/delete/saved-video", { videoId: videoId })
      .then((res) => {
        const { success } = res.data;
        if (success) return apiGetSavedVideos();
      })
      .catch((err) => console.error(err));
  };

  return (
    <Main>
      <Content>
        <ContentWrapper>
          <Heading title={"Saved Videos"} />

          <br />

          {savedVideosData.length > 0 ? (
            savedVideosData.map((item) => {
              return (
                <div key={item.videoId} className="saved-items__card">
                  <span
                    className="saved-items__title"
                    onClick={() =>
                      handleTitleOnClick(item.videoId, item.partnerId)
                    }
                  >
                    {item.title}
                  </span>
                  <span
                    className="saved-items__channel-name"
                    onClick={() => handleChannelOnClick(item.userName)}
                  >
                    {item.channelName}
                  </span>

                  <span className="saved-items__remove-btn">
                    <PiTrashSimpleBold
                      onClick={() => handleRemoveVideoOnClick(item.videoId)}
                    />
                  </span>
                </div>
              );
            })
          ) : (
            <NoDataDialog
              title="No Saved Videos"
              message={"Your saved videos will be showing up here."}
            />
          )}
        </ContentWrapper>
      </Content>
    </Main>
  );
};

export default SavedItemsPage;
