import React, { useEffect, useState } from "react";
import Axios from "../../../../../api/axios/Axios";
import { FormatDateForInput } from "../../../../../hooks/DateTime";
import TransactionTable from "../components/Table";
import Heading from "../../../../../components/heading/Heading";

const AdminTransactionList = () => {
  const [videoData, setVideoData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    setFirstDayOfCurrentMonth();
    setLastDayOfCurrentMonth();
  }, []);

  useEffect(() => {
    if (fromDate && toDate) return filterBySelectedDate();
  }, [fromDate, toDate]);

  const setFirstDayOfCurrentMonth = () => {
    const currentDate = new Date();
    const formatDate = FormatDateForInput(currentDate.setDate(1));
    return setFromDate(formatDate);
  };

  const setLastDayOfCurrentMonth = () => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1);
    const formatDate = FormatDateForInput(currentDate.setDate(0));
    return setToDate(formatDate);
  };

  const filterBySelectedDate = () => {
    const dates = { fromDate: fromDate, toDate: toDate, offSet: 0 };
    const isEmpty = Object.values(dates).every(
      (val) => val !== undefined && val !== null && val !== ""
    );  

    if (!isEmpty) return alert("Select date");
    return apiGetPaymentsList(dates);
  };

  const apiGetPaymentsList = (dates) => {
    Axios.get("/select/admin/payments/list", {
      params: dates,
    })
      .then((res) => {
        const data = res.data;
        return setVideoData(data);
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      <span className="main-content_heading__span">
        <Heading title="Transaction" />

        <span className="admin-transactions_date__span">
          <label>
            From
            <input
              type="date"
              defaultValue={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </label>

          <label>
            To
            <input
              type="date"
              defaultValue={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </label>
        </span>
      </span>

      <br />

      <TransactionTable data={videoData} />
    </>
  );
};

export default AdminTransactionList;
