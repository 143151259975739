import React, { useEffect, useState } from "react";
import Axios from "../../../api/axios/Axios";

const Filters = ({ selectedFilter }) => {
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("All");

  const handleSelectedValue = (value) => {
    selectedFilter(value);
    return setSelectedValue(value);
  };

  useEffect(() => apiSelectFilters(), []);

  const apiSelectFilters = () => {
    Axios.get("/select/public/filters")
      .then((res) => {
        const data = res.data;
        const sortedData = [...data].sort((a, b) =>
          a.filters === "All" ? -1 : b.filters === "All" ? 1 : 0
        );

        return setData(sortedData);
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="home-search-filters__content">
      <ul className="home-search-filters">
        {data.map((value, index) => {
          return (
            <li
              key={index}
              className={
                value.filters === selectedValue ? "active-filter" : null
              }
              onClick={() => handleSelectedValue(value.filters)}
            >
              {value.filters}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Filters;
